// Firebase
import { db } from "config/firebase";
import { collection, query, where, getDocs, doc } from "firebase/firestore";

// Functions
import fetchSignature from "./fetchSignature";

// A ---------------------------------------------------------------------- M

const fetchRecords = async (tagID, isTagGroupMember) => {
  try {
    const tagRef = doc(db, "tagsdata", tagID);
    const signaturesRef = collection(tagRef, "signatures");

    let q;

    if (isTagGroupMember) {
      q = query(
        signaturesRef,
        where("type", "not-in", ["mps", "sensor_data_aggregated", "cfg"])
      );
    } else {
      q = query(
        signaturesRef,
        where("type", "not-in", ["mps", "sensor_data_aggregated", "cfg"]),
        where("public", "==", true)
      );
    }

    const snapshot = await getDocs(q);
    const signaturePromises = snapshot.docs.map(async (sig) => {
      const txid = sig.data().txid;
      const isPublic = sig.data().public || false;
      const signature = await fetchSignature(txid, isPublic);
      return signature;
    });

    const signatures = await Promise.all(signaturePromises);
    const signaturesFiltered = signatures.filter((obj) => obj !== undefined);
    return signaturesFiltered;
  } catch (e) {
    console.error("Error in fetchRecords:", e.message);
    return null;
  }
};

export default fetchRecords;
