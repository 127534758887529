// Firebase
import { db } from "config/firebase";
import { doc, collection, getDocs, getDoc } from "firebase/firestore";

// Functions
import getDataKeyData from "datakey/getDataKeyData";

// A ---------------------------------------------------------------------- M

const getAuthorizedDataKeys = async (databoxID) => {
    try {
        const databoxRef = doc(db, 'tagsdata', databoxID);
        const authorizedKeysRef = collection(databoxRef, 'authorized_keys');
        const snapshot = await getDocs(authorizedKeysRef);

        const authorizedKeysPromises = snapshot.docs.map(async (authKey) => {
            const keyData = await getDataKeyData(authKey.id);

            let keyName;
            let keyOwner;

            if (keyData) {
                keyName = keyData.name;
                keyOwner = keyData.owner_uuid;
            }

            const keyAddedOn = authKey.data().added_on;
            const keySecretDocRef = doc(db, 'keysecrets', authKey.id);
            const keySecretSnapshot = await getDoc(keySecretDocRef);
            const keySecretData = keySecretSnapshot.exists() ? keySecretSnapshot.data() : {};

            return {
                id: authKey.id,
                name: keyName,
                added_on: keyAddedOn,
                owner_uuid: keyOwner,
                datakey: keySecretData.datakey || "",
            };
        });

        const authorizedKeysArray = await Promise.all(authorizedKeysPromises);

        return { authorizedKeysArray }
    } catch (e) {
        console.error("Error in getAuthorizedDataKeys:", e.message);
        return { authorizedKeysArray: [] }
    }
};

export default getAuthorizedDataKeys;