// React
import React, { useState, useEffect } from 'react';

// Context
import { UserAuth } from 'context/AuthContext';

// Material UI
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import { GroupCreationSuccessfulSnackbar } from 'ui-components/ORFeedbacks';
import GroupsTable from 'ui-components/GroupsTable';
import GroupGenerator from 'ui-components/GroupGenerator';

// Firebase
import { db } from 'config/firebase';
import { onSnapshot, query, where, collectionGroup } from 'firebase/firestore';

// Functions
import fetchUserGroups from 'group/fetchUserGroups';

// A ---------------------------------------------------------------------- M

const MyGroups = () => {
    const { user, canCreateGroup, conservSostL1 } = UserAuth();

    const [groups, setGroups] = useState([]);
    const [openCreateGroup, setOpenCreateGroup] = useState(false);
    const [openGroupCreationSuccessful, setOpenGroupCreationSuccessful] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getGroups = async () => {
            if (user) {
                const groups = await fetchUserGroups(user.uid);
                groups.sort((group1, group2) => group2.added_on - group1.added_on);
                setGroups(groups);
            }
        };

        const fetchData = async () => {
            await getGroups();
            setIsLoading(false);
        };

        fetchData();

        const currentTimestampInSeconds = Math.floor(Date.now() / 1000);

        const newAdditionsQueryGroups = query(
            collectionGroup(db, 'users'),
            where('uid', '==', user.uid),
            where('added_on', '>', currentTimestampInSeconds.toString())
        );

        const newAdditionsUnsubscribeGroups = onSnapshot(newAdditionsQueryGroups, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === 'added') {
                    getGroups();
                }
            });
        });

        const deletionQueryGroups = query(
            collectionGroup(db, 'users'),
            where('uid', '==', user.uid)
        );

        const deletionUnsubscribeGroups = onSnapshot(deletionQueryGroups, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === 'removed') {
                    getGroups();
                }
            });
        });

        return () => {
            newAdditionsUnsubscribeGroups();
            deletionUnsubscribeGroups();
        };
    }, [user]);

    const handleOpenGroupGenerator = () => {
        setOpenCreateGroup(true);
        window.history.pushState(null, '');
    };

    const handleOpenGroupCreationSuccessful = () => {
        setOpenGroupCreationSuccessful(true);
        window.history.pushState(null, '');
    };

    return (
        <>
            {isLoading ?
                <Grid item container justifyContent='center' mt='30%'>
                    <CircularProgress />
                </Grid> :
                <Grid item xs={12}>
                    <GroupsTable
                        groups={groups}
                        handleOpenGroupGenerator={handleOpenGroupGenerator}
                    />
                </Grid>
            }

            {(canCreateGroup || conservSostL1) &&
                <>
                    <GroupGenerator open={openCreateGroup} setOpen={setOpenCreateGroup} handleSuccessful={handleOpenGroupCreationSuccessful} />
                    <GroupCreationSuccessfulSnackbar open={openGroupCreationSuccessful} setOpen={setOpenGroupCreationSuccessful} />
                </>
            }
        </>
    );
};

export default MyGroups;