const exportCSV = (csvArray) => {
    try {
        const header = 'id,timestamp,value,uom\n';
        const csvFile = header + csvArray.map(row => `${row.id},${row.timestamp},${row.value},${row.uom}\n`).join('');
        const link = document.createElement('a');
        const blob = new Blob([csvFile], { type: 'text/csv' });
        link.href = URL.createObjectURL(blob);
        link.download = 'records.csv';
        link.click();
    } catch (e) {
        console.error("Export csv failed: ", e.message);
    }
};

export default exportCSV;