// React
import React, { useState } from "react";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

// Icons
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// Functions
import {
  generateMnemonic,
  mnemonicToEntropy,
} from "SafeTwin/crypto/bip39/bip39";
import {
  toHex,
  fromHex,
  toBase64,
  generateSeedEd25519KeyPair,
} from "SafeTwin/crypto/cryptolibsodium";

// A ---------------------------------------------------------------------- M

const KeyPairGenerator = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [privateKey, setPrivateKey] = useState("");
  const [publicKey, setPublicKey] = useState("");

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const generateKeyPair = () => {
    // Generate mnemonic
    const mnemonic = generateMnemonic();

    // Derive entropy from mnemonic
    const seed = mnemonicToEntropy(mnemonic);

    // Generate keypair
    const keypair = generateSeedEd25519KeyPair(fromHex(seed));

    // Private key
    const privateKeyByteArray = keypair.sk;
    const privateKeyBase64 = toBase64(privateKeyByteArray);

    // Public key
    const publicKeyByteArray = keypair.pk;
    const publicKeyHex = toHex(publicKeyByteArray);

    setPrivateKey(privateKeyBase64);
    setPublicKey(publicKeyHex);
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          mt: isMobile ? 15 : 30,
          p: isMobile ? 2 : 3,
          boxShadow: 3,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid item container xs={12}>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant={isMobile ? "h6" : "h5"}
                align="center"
                gutterBottom
              >
                {t("generate_keypair")}
              </Typography>
              <Typography gutterBottom>
                {t("gen_key_1")}
                <br />- <b>{t("private_key")}</b>: {t("gen_key_2")}
                <br />- <b>{t("public_key")}</b>: {t("gen_key_3")}
              </Typography>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  id="Private Key"
                  label={t("private_key")}
                  name="Private Key"
                  required
                  fullWidth
                  variant="outlined"
                  value={privateKey}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => handleCopyToClipboard(privateKey)}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="Public Key"
                  label={t("public_key")}
                  name="Public Key"
                  variant="outlined"
                  value={publicKey}
                  required
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => handleCopyToClipboard(publicKey)}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item container xs={12}>
                <Button variant="contained" onClick={generateKeyPair} fullWidth>
                  {t("generate")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default KeyPairGenerator;
