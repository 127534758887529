// Firebase
import { db } from "config/firebase";
import { doc, collection, getDocs } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

/**
 * Retrieves all the public keys enabled by a user.
 *
 * @param {string} uuid - The unique identifier of the user.
 * @returns {Promise<Array>} A promise that resolves to an array of enabled public keys.
 */

const getActivatedPublicKeys = async (uuid) => {
    try {
        // Reference to the user's document
        const userRef = doc(db, 'enabled_records', uuid);

        // Reference to the 'enabled_public_keys' collection
        const collectionRef = collection(userRef, 'enabled_public_keys');

        // Getting the documents
        const snapshot = await getDocs(collectionRef);

        const publicKeysData = [];

        snapshot.docs.forEach((doc) => {
            publicKeysData.push({ ...doc.data() });
        });

        return publicKeysData;
    } catch (e) {
        console.error('Error in getActivatedPublicKeys:', e.message);

        return [];
    }
};

export default getActivatedPublicKeys;