// React
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// react-xml-viewer
import XMLViewer from "react-xml-viewer";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

// Icons
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FileOpenIcon from "@mui/icons-material/FileOpen";

// Functions
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CDNFileDetailsDialog = ({ open, onClose, record }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [selectedFile, setSelectedFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [xmlContent, setXmlContent] = useState("");

  const fields = useMemo(
    () => [
      {
        label: "Generation Datetime",
        value: convertTimestamp(record.timestamp),
      },
      { label: "Nome File", value: record.fileName },
      { label: "Formato File", value: record.fileType },
      {
        label: "Nome File Di Destinazione",
        value: record.nomeFileDestinazione,
      },
      { label: "Cartella Di Destinazione", value: record.cartellaDestinazione },
      { label: "Classe Documentale", value: record.classeDocumentale },
      { label: "Data Documento", value: record.dataDocumento },
      { label: "Impronta", value: record.impronta },
      { label: "Algoritmo", value: record.algoritmo },
      {
        label: "Identificativo Documento",
        value: record.identificativoDocumento,
      },
      { label: "Versione Del Documento", value: record.versioneDocumento },
      { label: "Modalità Di Formazione", value: record.modalitaFormazione },
      { label: "Tipologia Di Flusso", value: record.tipologiaFlusso },
      { label: "Tipo Registro", value: record.tipoRegistro },
      { label: "Data Registrazione", value: record.dataRegistrazione },
      { label: "Numero Documento", value: record.numeroDocumento },
      { label: "Codice Registro", value: record.codiceRegistro },
      { label: "Oggetto", value: record.oggetto },
      { label: "Ruolo", value: record.ruolo },
      { label: "Tipo Soggetto", value: record.tipoSoggetto },
      { label: "Cognome", value: record.cognome },
      { label: "Nome", value: record.nome },
      { label: "Denominazione", value: record.denominazione },
      { label: "Codice Fiscale", value: record.codiceFiscale },
      {
        label: "Indirizzi Digitali Di Riferimento",
        value: record.indirizziDigitaliDiRiferimento,
      },
      { label: "Allegati Numero", value: record.allegatiNumero },
      { label: "IdDoc Indice Allegati", value: record.IdDocIndiceAllegati },
      { label: "Descrizione Allegati", value: record.descrizioneAllegati },
      {
        label: "Indice Di Classificazione",
        value: record.indiceDiClassificazione,
      },
      {
        label: "Descrizione Classificazione",
        value: record.descrizioneClassificazione,
      },
      { label: "Riservato", value: record.riservato },
      { label: "Piano Classificazione", value: record.pianoClassificazione },
      { label: "Prodotto Software Nome", value: record.prodottoSoftwareNome },
      {
        label: "Prodotto Software Versione",
        value: record.prodottoSoftwareVersione,
      },
      {
        label: "Prodotto Software Produttore",
        value: record.prodottoSoftwareProduttore,
      },
      { label: "Verifica Firma Digitale", value: record.verificaFirmaDigitale },
      {
        label: "Verifica Marca Temporale",
        value: record.verificaMarcaTemporale,
      },
      { label: "Verifica Sigillo", value: record.verificaSigillo },
      {
        label: "Verifica Conformità Copie",
        value: record.verificaConformitaCopie,
      },
      { label: "Id Aggregazione", value: record.IdAggregazione },
      {
        label: "Identificativo Documento Principale",
        value: record.IdentificativoDocumentoPrincipale,
      },
      {
        label: "Tracciatura Modifiche - Tipo",
        value: record.tracciaturaModificheTipo,
      },
      {
        label: "Soggetto Autore Della Modifica",
        value: record.soggettoAutoreModifica,
      },
      {
        label: "Tracciatura Modifiche - Data",
        value: record.tracciaturaModificheData,
      },
      { label: "Tempo Di Conservazione", value: record.tempoConservazione },
      {
        label: "Tracciatura Modifiche - IdDoc Versione Precedente",
        value: record.tracciaturaModificheIdDocVersionePrecedente,
      },
      { label: "Note", value: record.note },
    ],
    [record]
  );

  const handleFileClick = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const fileType = blob.type;

      if (fileType === "text/xml" || fileType === "application/xml") {
        const xmlText = await blob.text();
        setXmlContent(xmlText);
      }

      setSelectedFile(fileUrl);
    } catch (error) {
      console.error("Error fetching and parsing file:", error);
    }
  };

  const handleCloseDialog = () => {
    setSelectedFile(null);
    setXmlContent("");
    setCurrentPage(1);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderFields = () =>
    fields.map((field, index) =>
      field.value ? (
        <Grid item xs={12} sm={4} key={index}>
          <TextField
            label={field.label}
            value={field.value}
            fullWidth
            InputProps={{ readOnly: true }}
            variant="outlined"
            sx={{ backgroundColor: "#f5f5f5" }}
          />
        </Grid>
      ) : null
    );

  const renderFileDialog = () => {
    switch (record.fileType) {
      case "application/pdf":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="md"
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                sx={{ color: "red" }}
                onClick={handleCloseDialog}
                aria-label="close"
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Document
              file={selectedFile}
              onLoadSuccess={handleDocumentLoadSuccess}
            >
              <Page
                pageNumber={currentPage}
                width={isMobile ? 300 : 600}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </Document>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                aria-label="previous page"
              >
                <KeyboardArrowLeftOutlinedIcon />
              </IconButton>
              <Typography>{`${currentPage} / ${numPages}`}</Typography>
              <IconButton
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === numPages}
                aria-label="next page"
              >
                <KeyboardArrowRightOutlinedIcon />
              </IconButton>
            </Box>
          </Dialog>
        );

      case "image/png":
      case "image/jpg":
      case "image/jpeg":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="md"
          >
            <Box sx={{ position: "relative" }}>
              <IconButton
                sx={{
                  position: "absolute",
                  top: "2%",
                  right: "2%",
                  color: "red",
                }}
                onClick={handleCloseDialog}
                aria-label="close"
              >
                <CloseOutlinedIcon />
              </IconButton>
              <img
                src={selectedFile}
                alt="Document"
                style={{ width: "100%" }}
              />
            </Box>
          </Dialog>
        );

      case "text/xml":
      case "application/xml":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="md"
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                sx={{ color: "red" }}
                onClick={handleCloseDialog}
                aria-label="close"
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
              <XMLViewer xml={xmlContent} />
            </Box>
          </Dialog>
        );

      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <Grid container spacing={3} p={3}>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Typography variant="h5" fontWeight="bold">
            Upload Details
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{ color: "red" }}
            aria-label="close"
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="ID"
            value={record.id}
            fullWidth
            InputProps={{ readOnly: true }}
            variant="outlined"
            sx={{ backgroundColor: "#f5f5f5" }}
          />
        </Grid>
        {renderFields()}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<FileOpenIcon />}
            onClick={() => handleFileClick(record.data.attachment)}
          >
            Open File
          </Button>
        </Grid>
      </Grid>
      {renderFileDialog()}
    </Dialog>
  );
};

CDNFileDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
};

export default CDNFileDetailsDialog;
