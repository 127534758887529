const unixTimestampInSeconds = () => {
    try {
        const unixTimestampInSeconds = Math.floor(Date.now() / 1000);
        const unixTimestampString = unixTimestampInSeconds.toString();
        return unixTimestampString;
    } catch (e) {
        console.error("Get current unix timestamp in seconds failed: ", e.message);
    }
};

export default unixTimestampInSeconds;