import { db } from "config/firebase";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

/**
 * Adds enabled API Key for a user.
 * @param {string} uuid - The user's UID.
 * @param {Object} data - The data to be added, must contain a APIKey.
 */
const activateAPIKey = async (uuid, data) => {
    if (!data.APIKey) console.error("APIKey is required in the data object");

    try {
        // Reference to the user's document
        const userRef = doc(db, 'enabled_records', uuid);

        // Retrieve the user document
        const userDoc = await getDoc(userRef);

        // If the user document does not exist, create a new one
        if (!userDoc.exists()) {
            await setDoc(userRef, {});
        }

        // Reference to the 'enabled_api_keys' sub-collection under the user document
        const enabledAPIKeysRef = collection(userRef, 'enabled_api_keys');

        const keyData = {
            ...data,
            added_on: unixTimestampInSeconds()
        };

        // Add a new document in 'enabled_api_keys' collection with the provided data.APIKey
        await setDoc(doc(enabledAPIKeysRef, data.APIKey), keyData);

        return {
            success: true,
            APIKey: data.APIKey,
        };
    } catch (e) {
        console.error("Error in activateAPIKey:", e.message);

        return {
            success: false,
            message: `Error in activateAPIKey: ${e.message}`,
        };
    }
};

export default activateAPIKey;