// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

/**
 * Checks if a document with the specified tagID exists in the tagsdata collection.
 * @param {string} tagID - The ID of the document to check.
 * @returns {boolean} - True if the document exists, false otherwise.
 */
const doesTagExist = async (tagID) => {
  try {
    const docRef = doc(db, "tagsdata", tagID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log(`Document with tagID ${tagID} exists.`);
      return true;
    } else {
      console.log(`No document found with tagID ${tagID}`);
      return false;
    }
  } catch (e) {
    console.error("Error in doesTagExist:", e.message);
    return false;
  }
};

export default doesTagExist;
