// Firebase
import { db } from "config/firebase";
import { doc, collection, getDocs } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

// Functions
import getGroup from "group/getGroup";
import getUserContacts from "user/getUserContacts";

const getViewGroupsReadOnly = async (tagID) => {
    try {
        const tagRef = doc(db, 'tagsdata', tagID);
        const viewgroupsRef = collection(tagRef, 'viewgroups_readonly');
        const viewgroups = await getDocs(viewgroupsRef);

        const viewgroupsPromises = viewgroups.docs.map(async (viewgroup) => {
            const group = await getGroup(viewgroup.id);
            const creator_contacts = await getUserContacts(group.creator_uuid);
            const creator_email = creator_contacts.email;

            return {
                ...viewgroup.data(),
                ...group,
                creator_email,
                permissions: 'r',
            };
        });

        const groups = await Promise.all(viewgroupsPromises);
        return groups;
    } catch (e) {
        console.error("Error in getViewGroupsReadOnly:", e.message);
        return [];
    }
};

export default getViewGroupsReadOnly;