// Firebase
import { db } from "config/firebase";
import { doc, updateDoc } from 'firebase/firestore';

// Functions
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

/**
 * Enables a forward route in Firestore for a given data box.
 * This is achieved by setting the 'enabled' property of the specified route to true.
 *
 * @param {string} tagID - The unique identifier of the data box in the Firestore collection.
 * @param {string} routeID - The unique identifier of the forward route in the 'forward_routes' subcollection.
 * @returns {Promise<void>} A promise that resolves when the update is successful.
 */
const enableForwardRoute = async (tagID, routeID) => {
    try {
        const routeRef = doc(db, 'tagsdata', tagID, 'forward_routes', routeID);
        
        await updateDoc(routeRef, {
            enabled: true,
            last_modified: unixTimestampInSeconds()
        });
    } catch (e) {
        console.error("Error in enableForwardRoute:", e.message);
    }
};

export default enableForwardRoute;