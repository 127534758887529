// Firebase
import { db } from 'config/firebase';
import { collection, getDocs } from 'firebase/firestore';

// A ---------------------------------------------------------------------- M

const getTags = async () => {
    try {
        const snapshot = await getDocs(collection(db, 'tagsdata'));

        let tagIDs = [];

        snapshot.docs.forEach((doc) => {
            tagIDs.push(doc.id);
        });

        return tagIDs;
    } catch (e) {
        console.error("Error in getTags:", e.message);
        return [];
    }
};

export default getTags;