import { getDocs, query, where, collectionGroup, getDoc } from 'firebase/firestore';
import { db } from 'config/firebase';
import getUserContacts from 'user/getUserContacts';

// A ---------------------------------------------------------------------- M

/**
 * Fetches groups that the user is a part of. Each group's data includes the group ID,
 * the time the user was added, the group creator's email, and other group details.
 *
 * @param {string} uid - The UID of the user for whom to fetch the groups.
 * @returns {Promise<Object[]>} A promise that resolves to an array of group objects.
 * Each object contains the group's ID, the added_on timestamp, the creator's email, and other group data.
 * Returns an empty array in case of an error.
 */
const fetchUserGroups = async (uid) => {
    try {
        const usersQuery = query(
            collectionGroup(db, 'users'),
            where('uid', '==', uid)
        );

        const snapshot = await getDocs(usersQuery);
        const groupPromises = snapshot.docs.map(async (user) => {
            const added_on = user.data().added_on;
            const group = await getDoc(user.ref.parent.parent);
            const id = group.id;
            const data = group.data();
            const creator_contacts = await getUserContacts(data.creator_uuid);
            const creator_email = creator_contacts.email;

            return {
                id,
                added_on,
                creator_email,
                ...data,
            };
        });

        const groups = await Promise.all(groupPromises);
        return groups;
    } catch (e) {
        console.error("Error in fetchUserGroups:", e.message);
        return [];
    }
};

export default fetchUserGroups;
