import { doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

/**
 * Retrieves the public key for a given user UUID from Firestore.
 * @param {string} uuid - The UUID of the user.
 * @returns {Promise<string>} A promise that resolves to the public key or empty string;
 */
const getPublicKey = async (uuid) => {
    try {
        const userRef = doc(db, 'usercontacts', uuid);
        const userDoc = await getDoc(userRef);
        return userDoc.exists() ? userDoc.data().public_key ?? '' : '';
    } catch (e) {
        console.error('Error in getPublicKey:', e.message);
        return '';
    }
};

export default getPublicKey;    