const downloadFile = async (downloadURL, fileName) => {
    try {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
            const blob = xhr.response;
            const a = document.createElement('a');
            a.style.display = 'none';
            const blobUrl = window.URL.createObjectURL(blob);
            a.href = blobUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(blobUrl);
        };

        xhr.open('GET', downloadURL);
        xhr.send();
    } catch (e) {
        console.error('Error in downloading file:', e.message);
    }
};

export default downloadFile;
