// Firebase
import { db } from 'config/firebase';
import { doc, collection, getDocs } from 'firebase/firestore';

// A ---------------------------------------------------------------------- M

const fetchSeenTags = async (uuid) => {
	try {
		const userRef = doc(db, 'userdata', uuid);

		const seentagsRef = collection(userRef, 'seentags');

		const snapshot = await getDocs(seentagsRef);

		let seentags = [];

		snapshot.docs.forEach((doc) => {
			seentags.push({
				id: doc.id,
				nickname: doc.data().nickname ? doc.data().nickname : "N/A",
				notes: doc.data().notes ? doc.data().notes : "N/A",
				firstseen: doc.data().firstseen ? doc.data().firstseen : "N/A",
			});
		});

		return seentags;
	} catch (e) {
		console.error('Error in fetchSeenTags:', e.message);
		return [];
	}
};

export default fetchSeenTags;