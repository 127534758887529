// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import CDNFileDetailsDialog from "./CDNFileDetailsDialog";

// Material UI
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// Icons
import FirstPageOutlinedIcon from "@mui/icons-material/FirstPageOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import LastPageOutlinedIcon from "@mui/icons-material/LastPageOutlined";
import TaskIcon from "@mui/icons-material/Task";

// Functions
import convertTimestamp from "utils/convertTimestamp";
import downloadFile from "storage/downloadFile";

// A ---------------------------------------------------------------------- M

const headCells = [
  {
    id: "preservationProof",
    label: "Prova Di Conservazione",
    mobileLabel: "Prova Di Conservazione",
  },
  {
    id: "timestamp",
    label: "Generation Datetime",
    mobileLabel: "Generation Datetime",
  },
  {
    id: "ID",
    label: "ID",
    mobileLabel: "ID",
  },
  { id: "fileName", label: "Nome File", mobileLabel: "File Name" },
  {
    id: "documentClass",
    label: "Classe Documentale",
    mobileLabel: "Classe Documentale",
  },
  { id: "status", label: "Stato", mobileLabel: "Stato" },
  { id: "actions", label: "Azioni", mobileLabel: "Azioni" },
];

const CDNFileTableHead = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          (headCell) =>
            (!isMobile ||
              headCell.id === "preservationProof" ||
              headCell.id === "timestamp" ||
              headCell.id === "ID" ||
              headCell.id === "fileName" ||
              headCell.id === "documentClass" ||
              headCell.id === "status" ||
              headCell.id === "actions") && (
              <TableCell
                key={headCell.id}
                align={headCell.id !== "actions" ? "left" : "right"}
              >
                {isMobile ? headCell.mobileLabel : headCell.label}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
};

const TablePaginationActions = (props) => {
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <LastPageOutlinedIcon />
        ) : (
          <FirstPageOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRightOutlinedIcon />
        ) : (
          <KeyboardArrowLeftOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeftOutlinedIcon />
        ) : (
          <KeyboardArrowRightOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageOutlinedIcon />
        ) : (
          <LastPageOutlinedIcon />
        )}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const CDNFileTable = ({ records, handleOpenPreservationProofDialog }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - records.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFirstPageButtonClick = () => {
    setPage(0);
  };

  const handleBackButtonClick = () => {
    setPage(page - 1);
  };

  const handleNextButtonClick = () => {
    setPage(page + 1);
  };

  const handleLastPageButtonClick = () => {
    setPage(Math.max(0, Math.ceil(records.length / rowsPerPage) - 1));
  };

  const handleOpenDetails = (record) => {
    setSelectedRecord(record);
    setDetailsDialogOpen(true);
  };

  const handleCloseDetails = () => {
    setDetailsDialogOpen(false);
    setSelectedRecord(null);
  };

  return (
    <Box>
      <TableContainer>
        <Table>
          <CDNFileTableHead />
          <TableBody>
            {(rowsPerPage > 0
              ? records.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : records
            ).map((record) => {
              return (
                <TableRow
                  key={record.id}
                  sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}
                >
                  <TableCell align="center" size="small">
                    <IconButton
                      onClick={() => handleOpenPreservationProofDialog(record)}
                    >
                      <TaskIcon fontSize="large" sx={{ color: "green" }} />
                    </IconButton>
                  </TableCell>
                  <TableCell align="left">
                    {convertTimestamp(record.timestamp) || "N/A"}
                  </TableCell>
                  <TableCell align="left">{record.id}</TableCell>
                  <TableCell
                    align="left"
                    sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {record.fileName || "N/A"}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {record.classeDocumentale || "N/A"}
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2" color="green">
                      UPLOADED
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      size="small"
                      onClick={() => handleOpenDetails(record)}
                    >
                      Details
                    </Button>
                    <Button
                      size="small"
                      onClick={() =>
                        downloadFile(record.data.attachment, record.fileName)
                      }
                    >
                      Download
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, { label: "All", value: -1 }]}
                  colSpan={7}
                  count={records.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {isMobile && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
            >
              <FirstPageOutlinedIcon />
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(records.length / rowsPerPage) - 1}
            >
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(records.length / rowsPerPage) - 1}
            >
              <LastPageOutlinedIcon />
            </IconButton>
          </Box>
        )}
      </TableContainer>

      {selectedRecord && (
        <CDNFileDetailsDialog
          open={detailsDialogOpen}
          onClose={handleCloseDetails}
          record={selectedRecord}
        />
      )}
    </Box>
  );
};

export default CDNFileTable;
