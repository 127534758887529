// Firebase
import { db } from "config/firebase";
import { doc, collection, query, where, getDocs, getCountFromServer } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

/**
 * Asynchronously retrieves certifications created by a specific human/key within a given databox.
 * This function queries the 'signatures' sub-collection of a 'tagsdata' document, filtering by the creator's uid.
 * It also calculates the total number of certifications matching the criteria directly from the server.
 * 
 * @param {string} databoxID The ID of the 'tagsdata' document to query within.
 * @param {string} uid The unique identifier uid of the creator (human/key) to filter the certifications by.
 * @returns {Promise<Object>} An object containing an array of certifications and the total count of those certifications.
 */
const getCertificationsByCreator = async (databoxID, uid) => {
    try {
        const databoxRef = doc(db, 'tagsdata', databoxID);
        const signaturesRef = collection(databoxRef, 'signatures');
        const q = query(signaturesRef, where("creator_uuid", "==", uid));
        const querySnapshot = await getDocs(q);

        const certifications = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        const countSnap = await getCountFromServer(q);

        return {
            certifications: certifications,
            number_of_certifications: countSnap.data().count,
        };
    } catch (e) {
        console.error("Error in getCertificationsByCreator:", e.message);
        return null;
    }
};

export default getCertificationsByCreator;
