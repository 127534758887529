// Firebase
import { db } from "config/firebase";
import { doc, setDoc } from "firebase/firestore";

// Functions
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

/**
 * Adds an authorized key to the 'authorized_keys' subcollection of a specified 'tagsdata' document.
 * 
 * @param {string} tagID - The ID of the tag to which the authorized key is being added.
 * @param {string} keyID - The ID of the new authorized key document to be created.
 * @returns {Promise<void>} A promise that resolves when the document is successfully added.
 */
const addAuthorizedKey = async (tagID, keyID) => {
    try {
        const authorizedKeyRef = doc(db, `tagsdata/${tagID}/authorized_keys`, keyID);
        const addedOn = unixTimestampInSeconds();

        await setDoc(authorizedKeyRef, {
            key_id: keyID,
            added_on: addedOn,
        });
    } catch (e) {
        console.error("Error in addAuthorizedKey:", e.message);
    }
};

export default addAuthorizedKey;
