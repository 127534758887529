// React
import React, { useEffect, useState } from "react";

// Context
import { UserAuth } from "context/AuthContext";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// Components
import { Puller } from "ui-components/Puller";
import { LoadingComponent } from "ui-components/LoadingComponent";

// Functions
import activateAPIKey from "user/activateAPIKey";

// A ---------------------------------------------------------------------- M

const ActivateAPIKey = ({
  APIKey,
  open,
  setOpen,
  handleClose,
  setOpenAPIKeySnackbarSuccessful,
  setOpenAPIKeySnackbarError,
}) => {
  const { user } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  const handleActivate = async () => {
    setLoading(true);

    const data = { APIKey, name, company, type };
    const activation = await activateAPIKey(user.uid, data);

    if (activation.success) {
      setOpenAPIKeySnackbarSuccessful(true);
    } else {
      setOpenAPIKeySnackbarError(true);
    }

    setLoading(false);
    handleReset();
  };

  const handleReset = () => {
    handleClose();
    setName("");
    setCompany("");
    setType("");
  };

  const APIKeyForm = (
    <Box sx={{ p: isMobile ? "5%" : "2%" }}>
      <Grid
        container
        alignItems="center"
        justifyContent={isMobile ? "center" : "space-between"}
        mt={isMobile ? "5%" : "0%"}
      >
        <Grid item>
          <Typography variant="h5" fontWeight="bold">
            {t("activate_api_key")}
          </Typography>
        </Grid>
        {!isMobile && (
          <Grid item>
            <IconButton onClick={handleReset} sx={{ color: "red" }} edge="end">
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <TextField
        margin="dense"
        id="API Key"
        label={t("api_key")}
        name="API Key"
        required
        fullWidth
        variant="outlined"
        value={APIKey}
      />
      <TextField
        autoFocus
        margin="dense"
        id="Name"
        label={t("name")}
        name="Name"
        required
        fullWidth
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        margin="dense"
        id="Company"
        label={t("company")}
        name="Name"
        fullWidth
        variant="outlined"
        value={company}
        onChange={(e) => setCompany(e.target.value)}
      />
      <TextField
        margin="dense"
        id="type"
        label={t("type")}
        name="Type"
        fullWidth
        variant="outlined"
        value={type}
        onChange={(e) => setType(e.target.value)}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleActivate}
          disabled={!APIKey || !name}
        >
          {t("activate")}
        </Button>
      </Box>
    </Box>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{ "& .MuiDrawer-paper": { width: "100%" } }}
      PaperProps={{
        sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
      }}
    >
      <Puller />
      {loading ? <LoadingComponent /> : APIKeyForm}
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={handleReset} fullWidth maxWidth="md">
      {loading ? <LoadingComponent /> : APIKeyForm}
    </Dialog>
  );
};

export default ActivateAPIKey;
