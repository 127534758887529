import { decryptServerSeed } from "./decryptServerSeed";
import { encryptSeedByPasswordHash } from "../crypto/cryptoseed";

/**
 * Handles the flow of decrypting a server-side encrypted seed and then re-encrypting it for browser storage.
 * 
 * This function first decrypts the seed stored on the server using the user's password,
 * then re-encrypts it using the user's passwordhash before storing it in the browser's local storage.
 * 
 * @param {Object} user - The user object, which must contain the uid and reloadUserInfo with passwordHash.
 * @param {string} password - The user's password.
 * @returns {Promise<boolean>} Returns true if the operation succeeds, false otherwise.
 */
export const seedFlow = async (user, password) => {
    try {
        const passwordhash = user.reloadUserInfo.passwordHash;
        const uuid = user.uid;

        const server_decrypted_seed = await decryptServerSeed(uuid, password);
        const browser_encrypted_seed = encryptSeedByPasswordHash(server_decrypted_seed, passwordhash);

        if (browser_encrypted_seed) {
            window.localStorage.setItem('seed', browser_encrypted_seed);
            return true;
        }

        return false;
    } catch (e) {
        console.error("Error in seed flow process: ", e);
        return false;
    }
};