// React
import React, { useEffect } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

// Material UI Icons
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// Components
import { Puller } from "ui-components/Puller";

// Functions
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const DatapointIntegrityInspector = ({
  record,
  verification,
  open,
  setOpen,
}) => {
  console.log("Record:", record);
  console.log("Verification:", verification);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        setOpen(false);
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  const inspectorBody = !verification.tx_not_found ? (
    <Grid item xs={12}>
      <List disablePadding>
        <ListItem disablePadding>
          <ListItemText
            primary={
              <Typography sx={{ wordBreak: "break-all" }} gutterBottom>
                <b>{t("certification_id")}</b>
                <br />
                {verification.txid}
              </Typography>
            }
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary={
              <Typography>
                <b>{t("assigned_databox")}</b>:<br />
                {verification.tdr ? (
                  verification.tagname || verification.tdr
                ) : (
                  <i>{t("not_assigned")}</i>
                )}
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Divider sx={{ mt: "3%", mb: "3%" }} />

      {/* Integrity Checks */}
      <Typography variant="h6" align="left">
        {t("integrity_checks")}
      </Typography>
      <List sx={{ "& .MuiListItem-root": { mt: "3%", mb: "3%" } }}>
        {/* Timeliness */}
        <ListItem disablePadding>
          <ListItemAvatar>
            <IconButton
              sx={{
                bgcolor: verification.txid
                  ? verification.tx_timestamp && verification.tx_timestamp !== 0
                    ? "green"
                    : "gray"
                  : "red",
                color: "white",
              }}
            >
              <AccessTimeIcon />
            </IconButton>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                color={
                  verification.txid
                    ? verification.tx_timestamp &&
                      verification.tx_timestamp !== 0
                      ? "green"
                      : "gray"
                    : "red"
                }
                variant="h6"
              >
                {t("timeliness")}
              </Typography>
            }
            secondary={
              verification.txid &&
              verification.tx_timestamp &&
              verification.tx_timestamp !== 0 ? (
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  color="text.primary"
                >
                  {t("timeliness_text_1")}{" "}
                  <b>{convertTimestamp(verification.tx_timestamp)}.</b>
                </Typography>
              ) : (
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  color="text.primary"
                >
                  {verification.txid && verification.tx_timestamp === 0
                    ? t("loading")
                    : t("not_available")}
                </Typography>
              )
            }
          />
        </ListItem>
        <ListItem>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
              <Typography color="gray">
                {t("additional_information")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ wordBreak: "break-all" }} gutterBottom>
                <b>{t("transaction_id")}</b> <br />
                <span>{verification.txid}</span>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </ListItem>
        <Divider />

        {/* Validity */}
        <ListItem disablePadding>
          <ListItemAvatar>
            <IconButton
              sx={{
                bgcolor:
                  !verification.pubkey && !verification.pubkeyURL
                    ? "gray"
                    : verification.signature_status === "verified"
                    ? "green"
                    : "red",
                color: "white",
              }}
            >
              <TaskAltIcon />
            </IconButton>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                color={
                  !verification.pubkey && !verification.pubkeyURL
                    ? "gray"
                    : verification.signature_status === "verified"
                    ? "green"
                    : "red"
                }
                variant="h6"
              >
                {t("validity")}
              </Typography>
            }
            secondary={
              !verification.pubkey && !verification.pubkeyURL ? (
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  color="text.primary"
                >
                  {t("validity_text_1")}
                </Typography>
              ) : (
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  color="text.primary"
                >
                  {verification.signature_status === "verified" ? (
                    verification.server_id !==
                    "no server identity available" ? (
                      <>
                        {t("validity_text_2")} <b>{verification.server_id}</b>.
                      </>
                    ) : verification.pubkey ? (
                      <>
                        {t("validity_text_2")} <b>ARMILIS SA</b>.
                      </>
                    ) : (
                      <></>
                    )
                  ) : (
                    <>{t("signature_mismatch")}</>
                  )}
                </Typography>
              )
            }
          />
        </ListItem>
        <ListItem>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
              <Typography color="gray">
                {t("additional_information")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ wordBreak: "break-all" }}>
                <b>{t("signature")}</b>
                <br />
                <Typography
                  color={
                    !verification.pubkey && !verification.pubkeyURL
                      ? "gray"
                      : verification.signature_status === "verified"
                      ? "green"
                      : "red"
                  }
                  gutterBottom
                >
                  {verification.sig}
                </Typography>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </ListItem>
        <Divider />

        {/* Consistency */}
        <ListItem disablePadding>
          <ListItemAvatar>
            <IconButton
              sx={{
                bgcolor:
                  verification.txid && verification.hsh && verification.check
                    ? "green"
                    : "red",
                color: "white",
              }}
            >
              <AllInclusiveIcon />
            </IconButton>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                color={
                  verification.txid && verification.hsh && verification.check
                    ? "green"
                    : "red"
                }
                variant="h6"
              >
                {t("consistency")}
              </Typography>
            }
            secondary={
              <>
                {verification.txid && verification.hsh && verification.check ? (
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    color="text.primary"
                  >
                    {t("consistency_text_1")} <b>{t("unaltered")}</b>{" "}
                    {t("consistency_text_2")}
                  </Typography>
                ) : (
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    color="text.primary"
                  >
                    {verification.txid &&
                    verification.hsh &&
                    !verification.check ? (
                      <>
                        {t("consistency_text_1")} <b>{t("altered")}</b>{" "}
                        {t("consistency_text_3")}
                      </>
                    ) : (
                      t("not_available")
                    )}
                  </Typography>
                )}
              </>
            }
          />
        </ListItem>
        <ListItem>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
              <Typography color="gray">
                {t("additional_information")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ wordBreak: "break-all" }} gutterBottom>
                <b>{t("record_hash")}</b> <br />
                <span style={{ color: verification.check ? "green" : "red" }}>
                  {verification.record_hash}
                </span>
              </Typography>
              <Typography sx={{ wordBreak: "break-all" }} gutterBottom>
                <b>{t("source_hash")}</b> <br />
                <span style={{ color: verification.check ? "green" : "red" }}>
                  {verification.hsh}
                </span>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </ListItem>
        <Divider />

        {/* Accuracy */}
        <ListItem disablePadding>
          <ListItemAvatar>
            <IconButton
              sx={{
                bgcolor: verification.txid
                  ? verification.tdr
                    ? "green"
                    : "gray"
                  : "red",
                color: "white",
              }}
            >
              <GpsFixedIcon />
            </IconButton>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                color={
                  verification.txid
                    ? verification.tdr
                      ? "green"
                      : "gray"
                    : "red"
                }
                variant="h6"
              >
                {t("accuracy")}
              </Typography>
            }
            secondary={
              verification.txid && verification.tdr ? (
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  color="text.primary"
                >
                  {t("accuracy_text_1")}{" "}
                  <b>
                    {verification.tagname
                      ? verification.tagname
                      : verification.tdr}
                    .
                  </b>
                </Typography>
              ) : (
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  color="text.primary"
                >
                  {t("not_available")}
                </Typography>
              )
            }
          />
        </ListItem>
        <Divider />
      </List>
    </Grid>
  ) : (
    <Grid item xs={12}>
      <Typography variant="body1">{t("verification_unable")}.</Typography>
    </Grid>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(true)}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "90%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid container alignItems="center" justifyContent="center" mt="5%">
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {t("certification_details")}
            </Typography>
          </Grid>
          {inspectorBody}
        </Grid>
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("certification_details")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => setOpen(false)}
              edge="end"
              sx={{ color: "red" }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>{inspectorBody}</DialogContent>
    </Dialog>
  );
};

export default DatapointIntegrityInspector;
