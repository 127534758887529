// Firebase
import { db } from "config/firebase";
import { collection, setDoc, doc, query, where, getDocs, updateDoc } from "firebase/firestore";

// Functions
import { genRndStringPrintable } from "SafeTwin/crypto/cryptolibsodium";

// A ---------------------------------------------------------------------- M

const addNewUser = async (email, canCreateDatabox, canCreateGroup, canSign, isReseller, canAddUser, conservSostL1) => {
    try {
        const usercontactsRef = collection(db, 'usercontacts');
        const q = query(usercontactsRef, where("email", "==", email));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            // No existing user with the provided email, create new user
            const randomIndex = `awaiting_${genRndStringPrintable(10)}`;
            await setDoc(doc(usercontactsRef, randomIndex), {
                email: email,
                processed: false,
                canCreateDatabox: canCreateDatabox,
                canCreateGroup: canCreateGroup,
                canSign: canSign,
                isReseller: isReseller,
                canAddUser: canAddUser,
                conservSostL1: conservSostL1,
            });
        } else {
            // User exists, update userdata collection
            querySnapshot.forEach(async (document) => {
                const docId = document.id;
                const userdataRef = doc(db, 'userdata', docId);
                await updateDoc(userdataRef, {
                    canCreateDatabox: canCreateDatabox,
                    canCreateGroup: canCreateGroup,
                    canSign: canSign,
                    isReseller: isReseller,
                    canAddUser: canAddUser,
                    conservSostL1: conservSostL1,
                });
            });
        }
    } catch (e) {
        console.error("Error in addNewUser:", e.message);
    }
};

export default addNewUser;