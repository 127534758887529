// Firebase
import { db } from 'config/firebase';
import { doc, getDoc } from 'firebase/firestore';

// A ---------------------------------------------------------------------- M

const isCertificationRequestCompleted = async (tagID, requestID) => {
    try {
        const requestRef = doc(db, `tagsdata/${tagID}/signature_requests`, requestID);
        const requestSnap = await getDoc(requestRef);

        if (requestSnap.exists() && requestSnap.data().completed === true) {
            return true;
        }

        return false;
    } catch (e) {
        console.error("Error in isCertificationRequestCompleted:", e.message);
        return false;
    }
};

export default isCertificationRequestCompleted;