// React
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Context
import { UserAuth } from "context/AuthContext";

// Material UI
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

// Icons
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

// Generators
import DocGeneratorFrame from "ui-components/generators/iFrames/DocGeneratorFrame";
import ImageGeneratorFrame from "ui-components/generators/iFrames/ImageGeneratorFrame";

// Functions
import { seedFlow } from 'SafeTwin/3S/seedFlow';
import fetchRequest from "record/fetchRequest";
import isCertificationRequestCompleted from "tag/isCertificationRequestCompleted";

// A ---------------------------------------------------------------------- M

// ERRORS

// 0) CODE: 1000, MESSAGE: LOADING_ERROR
// 1) CODE: 1001, MESSAGE: INVALID_INPUT
// 2) CODE: 1002, MESSAGE: AUTH_FAILED
// 3) CODE: 1003, MESSAGE: INSUFFICIENT_PERMISSIONS
// 4) CODE: 1004, MESSAGE: CERT_STREAM_FAILED
// 5) CODE: 1005, MESSAGE: USER_REJECTED
// 6) CODE: 1006, MESSAGE: REQUEST_NOT_FOUND
// 7) CODE: 1007, MESSAGE: REQUEST_ALREADY_COMPLETED
// 8) CODE: 1008, MESSAGE: INTERNAL_ERROR

const FileCertificationRequest = () => {
    const { setSeedFlow, signIn, logout, user } = UserAuth();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const requestID = queryParams.get('requestID');
    const permlogin = queryParams.get('permlogin');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [request, setRequest] = useState();
    const [showRequest, setShowRequest] = useState(false);
    const [showSignin, setShowSignin] = useState(false);
    const [invalidInput, setInvalidInput] = useState(false);
    const [invalidRequest, setInvalidRequest] = useState(false);
    const [completedRequest, setCompletedRequest] = useState(false);
    const [invalidAccount, setInvalidAccount] = useState(false);
    const [internalError, setInternalError] = useState(false);
    const [insufficientPermissions, setInsufficientPermissions] = useState(false);
    const [openCertificationRejected, setOpenCertificationRejected] = useState(false);
    const [openCertificationSuccessful, setOpenCertificationSuccessful] = useState(false);
    const [openCertificationError, setOpenCertificationError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (user) logout();

        if (requestID) setShowSignin(true);
        else {
            console.error("Error Code 1001 - INVALID_INPUT");
            setInvalidInput(true);
            window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1001', message: 'INVALID_INPUT' } }, '*');
        }

        setIsLoading(false);
    }, []);

    const handleSubmit = e => {
        e.preventDefault();
        signIn(email, password)
            .then(async (currentUser) => {
                setShowSignin(false);

                try {
                    const outcome = await seedFlow(currentUser.user, password);
                    setSeedFlow(outcome);

                    if (outcome) {
                        const request = await fetchRequest(requestID);

                        if (request) {
                            console.log(request);
                            const completedRequest = await isCertificationRequestCompleted(request.tdr, requestID);

                            if (!completedRequest) {
                                setRequest(request);
                                setShowRequest(true);
                            } else {
                                console.error("Error code 1007 - REQUEST_ALREADY_COMPLETED");
                                setCompletedRequest(true);
                                window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1007', message: 'REQUEST_ALREADY_COMPLETED' } }, '*');
                            }
                        } else {
                            console.error("Error code 1006 - REQUEST_NOT_FOUND");
                            setInvalidRequest(true);
                            window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1006', message: 'REQUEST_NOT_FOUND' } }, '*');
                        }
                    } else {
                        console.error("Error Code 1003 - INSUFFICIENT_PERMISSIONS");
                        setInsufficientPermissions(true);
                        window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1003', message: 'INSUFFICIENT_PERMISSIONS' } }, '*');
                    }
                } catch (e) {
                    console.error("Error Code 1008 - INTERNAL_ERROR");
                    setInternalError(true);
                    window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1008', message: 'INTERNAL_ERROR' } }, '*');
                }
            }).catch((e) => {
                console.error("Error Code 1002 - AUTH_FAILED");
                setPassword('');
                setInvalidAccount(true);
                window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1002', message: 'AUTH_FAILED' } }, '*');
            });
    };

    return (
        <>
            {isLoading &&
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            }

            {invalidInput &&
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <ErrorIcon sx={{ fontSize: 100, color: 'red' }} />
                    <br />
                    <Typography variant="h6">Invalid Input: 'requestID' field is missing.</Typography>
                </Box>
            }

            {invalidRequest &&
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <ErrorIcon sx={{ fontSize: 100, color: 'red' }} />
                    <br />
                    <Typography variant="h6">Invalid Request: 'requestID' not found.</Typography>
                </Box>
            }

            {internalError &&
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <ErrorIcon sx={{ fontSize: 100, color: 'red' }} />
                    <br />
                    <Typography variant="h6">Internal Error</Typography>
                </Box>
            }

            {completedRequest &&
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <ErrorIcon sx={{ fontSize: 100, color: 'red' }} />
                    <br />
                    <Typography variant="h6">Completed Request: request {requestID} has already been completed.</Typography>
                </Box>
            }

            {insufficientPermissions &&
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <ErrorIcon sx={{ fontSize: 100, color: 'red' }} />
                    <br />
                    <Typography variant="h6">Insufficient Permissions: you are not allowed to certify.</Typography>
                </Box>
            }

            <Dialog open={showSignin} fullScreen>
                <DialogTitle align="center">Enter your credentials</DialogTitle>
                <Divider />
                <DialogContent>
                    <Box component="form" onSubmit={handleSubmit} noValidate>
                        {!permlogin && <Alert severity="warning">You will not stay logged in on this device.</Alert>}
                        <TextField
                            margin="dense"
                            id="Email Address"
                            label="Email Address"
                            name="Email Address"
                            required
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            id="password"
                            label="Password"
                            name="Password"
                            type="password"
                            required
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {invalidAccount && <Typography color='red'>Email address or password wrong. Try again.</Typography>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                        >
                            Sign In
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            {request && request.type === 'doc' &&
                <DocGeneratorFrame
                    tag={request.tdr}
                    open={showRequest}
                    setOpen={setShowRequest}
                    handleOpenCertificationRejected={() => setOpenCertificationRejected(true)}
                    handleOpenCertificationSuccessful={() => setOpenCertificationSuccessful(true)}
                    handleOpenCertificationError={() => setOpenCertificationError(true)}
                    request={request}
                />
            }

            {request && request.type === 'img' &&
                <ImageGeneratorFrame
                    tag={request.tdr}
                    open={showRequest}
                    setOpen={setShowRequest}
                    handleOpenCertificationRejected={() => setOpenCertificationRejected(true)}
                    handleOpenCertificationSuccessful={() => setOpenCertificationSuccessful(true)}
                    handleOpenCertificationError={() => setOpenCertificationError(true)}
                    request={request}
                />
            }

            {openCertificationRejected &&
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <ErrorIcon style={{ fontSize: 100, color: 'red' }} />
                    <br />
                    <Typography variant="h6">Certification Request Rejected</Typography>
                </Box>
            }

            {openCertificationSuccessful &&
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <CheckCircleIcon style={{ fontSize: 100, color: '#00FF00' }} />
                    <br />
                    <Typography variant="h6">Certification Successful</Typography>
                </Box>
            }

            {openCertificationError &&
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <ErrorIcon style={{ fontSize: 100, color: 'red' }} />
                    <br />
                    <Typography variant="h6">Certification Failed</Typography>
                </Box>
            }
        </>
    );
};

export default FileCertificationRequest;