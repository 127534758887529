import { doc, collection, getDoc, deleteDoc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";

// A ---------------------------------------------------------------------- M

const removeMember = async (memberUID, groupID) => {
    try {
        const groupRef = doc(db, 'groupsdata', groupID);
        const group = await getDoc(groupRef);
        const usersRef = collection(groupRef, 'users');
        const userRef = doc(usersRef, memberUID);

        await deleteDoc(userRef);

        await updateDoc(groupRef, {
            members: group.data().members - 1,
        });

        return { code: 0 };
    } catch (e) {
        console.error("Error in removeMember:", e.message);
        return { code: -1 };
    }
};

export default removeMember;