// Convert unix timestamp (in seconds) to human-readable string format

const convertTimestamp = (timestamp) => {
    try {
        var date = new Date(timestamp * 1000);
        var year = date.getFullYear();
        var month = (date.getMonth() + 1).toString().padStart(2, '0');
        var day = date.getDate().toString().padStart(2, '0');
        var hours = date.getHours().toString().padStart(2, '0');
        var minutes = date.getMinutes().toString().padStart(2, '0');
        var seconds = date.getSeconds().toString().padStart(2, '0');
        return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    } catch (e) {
        console.error("convert timestamp failed: ", e.message);
    }
};

export default convertTimestamp;