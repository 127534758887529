// React
import React from "react";
import { createContext, useContext, useEffect, useState } from "react";

// Firebase
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  reauthenticateWithCredential,
  EmailAuthProvider,
  sendPasswordResetEmail,
} from "firebase/auth";

// Context
import { auth } from "config/firebase";

// Components
import { LoadingComponent } from "ui-components/LoadingComponent";

// Functions
import getUserContacts from "user/getUserContacts";
import getUserData from "user/getUserData";

// A ---------------------------------------------------------------------- M

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [userCompany, setUserCompany] = useState();
  const [pubKey, setPubKey] = useState();
  const [APIKey, setAPIKey] = useState();
  const [canCreateDatabox, setCanCreateDatabox] = useState(false);
  const [canCreateGroup, setCanCreateGroup] = useState(false);
  const [canSign, setCanSign] = useState(false);
  const [isReseller, setIsReseller] = useState(false);
  const [canAddUser, setCanAddUser] = useState(false);
  const [conservSostL1, setConservSostL1] = useState(false);
  const [isInternal, setIsInternal] = useState(false);
  const [seedflow, setSeedFlow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const checkPassword = async (password) => {
    try {
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);
      return true;
    } catch (e) {
      console.error("Error in checkPassword:", e.message);
      return false;
    }
  };

  const logout = () => {
    try {
      window.localStorage.removeItem("seed");
      window.localStorage.removeItem("nonce");
      setPubKey();
      setAPIKey();
      setCanCreateDatabox(false);
      setCanCreateGroup(false);
      setCanSign(false);
      setIsReseller(false);
      setCanAddUser(false);
      setConservSostL1(false);
      setIsInternal(false);
      setSeedFlow(false);
      setIsLoading(false);
      return signOut(auth);
    } catch (e) {
      console.error("Error in logout:", e.message);
    }
  };

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (e) {
      console.error("Error in resetPassword:", e.message);
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("seed")) {
      setSeedFlow(true);
    }

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);

      if (currentUser && currentUser.uid) {
        try {
          // User Contacts
          const userContacts = await getUserContacts(currentUser.uid);

          if (userContacts) {
            // Company
            setUserCompany(userContacts.company);

            // Public Key
            setPubKey(userContacts.public_key);
          }

          // User Data
          const userData = await getUserData(currentUser.uid);

          if (userData) {
            // canCreateDatabox
            setCanCreateDatabox(userData.canCreateDatabox);

            // canCreateGroup
            setCanCreateGroup(userData.canCreateGroup);

            // canSign
            setCanSign(userData.canSign);

            // Reseller
            setIsReseller(userData.isReseller);

            // canAddUser
            setCanAddUser(userData.canAddUser);

            // conservSostL1
            setConservSostL1(userData.conservSostL1);

            // APIKey
            setAPIKey(userData.APIKey);

            // isInternal
            setIsInternal(userData.isInternal);
          }
        } catch (e) {
          console.error("Error in fetching user information:", e.message);
        }
      }

      setIsLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <UserContext.Provider
      value={{
        user,
        pubKey,
        setPubKey,
        userCompany,
        canCreateDatabox,
        canCreateGroup,
        canSign,
        isReseller,
        canAddUser,
        conservSostL1,
        setConservSostL1,
        APIKey,
        isInternal,
        seedflow,
        setSeedFlow,
        signIn,
        logout,
        checkPassword,
        resetPassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
