// Seed management cryptographic functions

import { calculateSHA256, encryptSecretBox, decryptSecretBox, fromHex, toHex, generateSeedKeyPair, generateSeedEd25519KeyPair } from "./cryptolibsodium";

// Symmetric encryption using passwordHash as secret key. Used for in-browser seed encryption into local storage
export const encryptSeedByPasswordHash = (seed, passwordhash) => {
    try {
        // take the first 24 bytes of seed SHA256 as nonce
        const seedSHA256 = calculateSHA256(fromHex(seed));
        const nonce = seedSHA256.slice(0, 24);
        window.localStorage.setItem('nonce', toHex(nonce));
        const encryptedseed = encryptSecretBox(fromHex(seed), calculateSHA256(passwordhash));
        return encryptedseed;
    } catch (e) {
        console.error("Encrypt seed by password hash failed: ", e.message);
    }
};

// Symmetric decryption using passwordHash as secret key. Used for in-browser seed decryption from local storage. It generates the corresponding keypair derived from seed for asymmetric encryption
export const decryptSeedByPasswordHash = (passwordHash) => {
    try {
        if (window.localStorage.getItem('seed')) {
            const seed_crypted = window.localStorage.getItem('seed');
            const seed = decryptSecretBox(fromHex(seed_crypted), calculateSHA256(passwordHash));
            const keypair = generateSeedKeyPair(seed);
            return keypair;
        }
    } catch (e) {
        console.error("Decrypt seed by password hash failed: ", e.message);
    }
};

// Symmetric decryption using passwordHash as secret key. Used for in-browser seed decryption from local storage. It generates the corresponding keypair derived from seed for Ed25519 signatures
export const decryptSeedByPasswordHash4Ed25519 = (passwordHash) => {
    try {
        if (window.localStorage.getItem('seed')) {
            const seed_crypted = window.localStorage.getItem('seed');
            const seed = decryptSecretBox(fromHex(seed_crypted), calculateSHA256(passwordHash));
            const keypair = generateSeedEd25519KeyPair(seed);
            return keypair;
        }
    } catch (e) {
        console.error("Decrypt seed by password hash for Ed25519 failed: ", e.message);
    }
};

// Symmetric encryption using sha512(password + salt) as secret key. Used for in-browser seed encryption into DB
export const encryptSeedBySHA512 = (seed, sha512) => {
    try {
        // take the first 24 bytes of seed SHA256 as nonce
        const seedSHA256 = calculateSHA256(fromHex(seed));
        const nonce = seedSHA256.slice(0, 24);
        const encryptedseed = encryptSecretBox(fromHex(seed), calculateSHA256(sha512), nonce);

        return {
            encrypted_seed: encryptedseed,
            nonce: toHex(nonce)
        }
    } catch (e) {
        console.error("Encrypt seed by sha512 failed: ", e.message);
    }
};

// Symmetric decryption using sha512(password + salt) as secret key. Used for in-browser seed decryption from DB
export const decryptSeedBySHA512 = (server_encrypted_seed, sha512, nonce) => {
    try {
        const decryptedseed = toHex(decryptSecretBox(fromHex(server_encrypted_seed), calculateSHA256(sha512), fromHex(nonce)));
        return decryptedseed;
    } catch (e) {
        console.error("Decrypt seed by sha512 failed: ", e.message);
    }
};