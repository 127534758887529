// A ---------------------------------------------------------------------- M

/**
 * Fetches and verifies a blockchain record using the Armilis transactionverify API.
 * @param {string} txid - The transaction ID.
 * @param {string} [pubkey] - The public key (optional).
 * @param {string} [pubkeyURL] - The public key URL (optional).
 * @returns {Promise<Object | Error>} - A promise that resolves to the API response or an error.
 */
const fetchBlockchainRecord = async (txid, pubkey, pubkeyURL) => {
	try {
		console.log('txid:', txid);
		console.log('pubkey:', pubkey);
		console.log('pubkeyURL:', pubkeyURL);

		let requestBody = { txid };

		if (pubkey) {
			requestBody.pubkey = pubkey;
		}

		if (pubkeyURL) {
			requestBody.pubkeyURL = pubkeyURL;
		}

		const response = await fetch('https://serving.armilis.com/api/v0.3a/transactionverify', {
			method: 'POST',
			body: JSON.stringify(requestBody),
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
			}
		});

		const data = await response.json();
		return data;
	} catch (e) {
		console.error('Error in fetchBlockchainRecord:', e.message);
		return null;
	}
};

export default fetchBlockchainRecord;