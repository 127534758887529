// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

/**
 * Retrieves the public data for a data key, identified by their keyID.
 *
 * @param {string} keyID - The keyID of the data key whose data is being retrieved.
 * @returns {Promise<{name: string, owner_uuid: boolean}>} A promise that resolves to an object containing the name and the owner_uuid.
 * If the key does not exist or in case of an error, it logs the error and returns an object with an empty string for the name and an empty string for the owner_uuid.
 */
const getDataKeyData = async (keyID) => {
  try {
    const keyRef = doc(db, "keydata", keyID);
    const keyDoc = await getDoc(keyRef);

    if (keyDoc.exists()) {
      const keyData = keyDoc.data();
      return {
        name: keyData.name || "",
        owner_uuid: keyData.owner_uuid || false,
        created_on: keyData.created_on || "",
      };
    } else {
      return {
        name: "",
        owner_uuid: false,
        created_on: "",
      };
    }
  } catch (e) {
    console.error("Error in getUserData:", e.message);
    return {
      name: "",
      owner_uuid: false,
      created_on: "",
    };
  }
};

export default getDataKeyData;
