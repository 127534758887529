import { collection, setDoc, doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";

const addSignature = async (uid, txid, timestamp, type, tdr) => {
    try {
        const userRef = doc(db, "userdata", uid);
        const userDoc = await getDoc(userRef);

        if (!userDoc.exists()) {
            await setDoc(userRef, {
                admin: false,
            });
        }

        const mysigsRef = collection(userRef, "mysigs");

        await setDoc(doc(mysigsRef, txid), {
            timestamp: timestamp,
            type: type,
            ...(tdr && { tdr: tdr }),
        });
    } catch (e) {
        console.error("Add signature failed: ", e.message);
    }
};

export default addSignature;