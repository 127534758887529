// React
import React, { useEffect, memo } from "react";
import PropTypes from "prop-types";

// i18n
import { useTranslation } from "react-i18next";

// Material UI Components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Grid,
  IconButton,
} from "@mui/material";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// A ---------------------------------------------------------------------- M

// Recursive component to display nested objects
const RecursiveDisplay = memo(({ data, level = 0 }) => {
  return (
    <Box sx={{ paddingLeft: level * 2 }}>
      {Object.entries(data).map(([key, value]) => (
        <Box key={key} sx={{ marginBottom: 1 }}>
          <Typography
            variant="body1"
            component="span"
            sx={{ fontWeight: "bold" }}
          >
            {key}:
          </Typography>{" "}
          {typeof value === "object" && value !== null ? (
            <RecursiveDisplay data={value} level={level + 1} />
          ) : (
            <Typography variant="body2" component="span">
              {String(value)}
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
});

const DataDialog = ({ data, open, onClose }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        onClose();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open, onClose]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="h5" fontWeight="bold">
            {t("record_details")}
          </Typography>
          <IconButton onClick={onClose} edge="end" sx={{ color: "red" }}>
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <RecursiveDisplay data={data} />
      </DialogContent>
    </Dialog>
  );
};

DataDialog.propTypes = {
  data: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DataDialog;
