// React
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

// React PWAInstallProvider library
import ReactPWAInstallProvider from 'react-pwa-install';

// Context
import { AuthContextProvider } from 'context/AuthContext';

// Service Worker
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';

// reportWebVitals
import reportWebVitals from 'reportWebVitals';

// Components
import App from 'App';

// i18n
import './i18n';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

// A ---------------------------------------------------------------------- M

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<ReactPWAInstallProvider>
		<Router>
			<I18nextProvider i18n={i18n}>
				<AuthContextProvider>
					<App />
				</AuthContextProvider>
			</I18nextProvider>
		</Router>
	</ReactPWAInstallProvider>
);

serviceWorkerRegistration.register();

reportWebVitals();