// React
import React, { useState, useEffect } from "react";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Button from "@mui/material/Button";

// Function
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const MpsTableHead = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const headCells = [
    {
      id: "timestamp",
      label: t("generation_datetime"),
      mobileLabel: t("generation_datetime"),
    },
    { id: "id", label: t("id"), mobileLabel: t("id") },
    { id: "value", label: t("value"), mobileLabel: t("value") },
    { id: "magnitude", label: t("magnitude"), mobileLabel: t("magnitude") },
    { id: "visibility", label: t("visibility"), mobileLabel: t("visibility") },
    { id: "actions", label: t("actions"), mobileLabel: t("actions") },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id !== "actions" ? "left" : "right"}
          >
            {isMobile ? headCell.mobileLabel : headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const MpsTable = ({
  records,
  selectedSensors,
  checkVerification,
  handleView,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [filteredRecords, setFilteredRecords] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState("");

  useEffect(() => {
    if (selectedSensor) {
      const filteredRecords = records.reduce((acc, record) => {
        if (record.sensorId === selectedSensor) {
          record.magnitudes.forEach((mag) => acc.push(...mag.records));
        }
        return acc;
      }, []);

      let reversed = [];

      for (let i = filteredRecords.length - 1; i >= 0; i--) {
        reversed.push(filteredRecords[i]);
      }

      setFilteredRecords(reversed);
    } else {
      setFilteredRecords([]);
    }
  }, [selectedSensor, records]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSensor = (event) => {
    setSelectedSensor(event.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <FormControl fullWidth>
          <InputLabel id="Sensors">{t("sensors")}</InputLabel>
          <Select
            labelId={t("sensors")}
            id="Sensors"
            value={selectedSensor}
            onChange={handleChangeSensor}
            input={<OutlinedInput label={t("sensors")} />}
          >
            {selectedSensors.map((sensorId) => (
              <MenuItem key={sensorId} value={sensorId}>
                {sensorId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <MpsTableHead />
            <TableBody>
              {(rowsPerPage > 0
                ? filteredRecords.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRecords
              ).map((record) => {
                return (
                  <TableRow
                    key={record.id}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    <TableCell align="left">
                      {record.data.timestamp
                        ? convertTimestamp(record.data.timestamp)
                        : "N/A"}
                    </TableCell>
                    {isMobile ? (
                      <TableCell align="left">
                        {record.id.substring(0, 6)}
                      </TableCell>
                    ) : (
                      <TableCell align="left">{record.id}</TableCell>
                    )}
                    <TableCell align="left">
                      {record.data.value || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.data.uom || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.public ? t("public") : t("private")}
                    </TableCell>
                    <TableCell align="right" size="small">
                      <Button onClick={() => handleView(record)}>
                        {t("details")}
                      </Button>
                      <Button onClick={() => checkVerification(record)}>
                        {t("verify")}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRecords.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default MpsTable;
