// React
import React, { useState, useEffect } from "react";

// Material UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Icons
import ErrorIcon from '@mui/icons-material/Error';

// A ---------------------------------------------------------------------- M

// ERRORS:

// 0) CODE: 1000, MESSAGE: LOADING_ERROR

export default function ErrorBoundary(props) {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const handleComponentError = (error) => {
            setHasError(true);
            window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1000', message: 'LOADING_ERROR' } }, '*');
        };

        window.addEventListener("QuickSignError", handleComponentError);

        return () => {
            window.removeEventListener("QuickSignError", handleComponentError);
        };
    }, []);

    if (hasError) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <ErrorIcon style={{ fontSize: 100, color: 'red' }} />
                <br />
                <Typography variant="h6">Error loading QuickSign</Typography>
            </Box>
        )
    }

    return props.children;
};