// Firebase
import { db } from "config/firebase";
import { collection, setDoc, doc } from "firebase/firestore";

// Functions
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

/**
 * Adds a seen tag record for a user in Firestore.
 * It adds a new document in the 'seentags' sub-collection of the user document,
 * using the `tagID` as the document ID. This document includes the notes
 * and the timestamp of when the tag was first seen.
 * 
 * @param {string} tag - The ID of the tag that has been seen by the user.
 * @param {string} uuid - The user's unique ID.
 * @param {string} name - The name of the tag that has been seen by the user.
 * @param {string} notes - The notes about the seen tag.
 */
const addSeenTag = async (tag, uuid, name, notes) => {
    try {
        // Reference to the user's document
        const userRef = doc(db, 'userdata', uuid);

        // Reference to the 'seentags' sub-collection under the user document
        const seentagsRef = collection(userRef, 'seentags');

        // Add a new document in 'seentags' collection with the provided tagID
        await setDoc(doc(seentagsRef, tag), {
            // Name about the tag
            nickname: name || '',
            // Optional notes about the tag
            notes: notes || '',
            // Timestamp when the tag was first seen   
            firstseen: unixTimestampInSeconds(),
        });
    } catch (e) {
        console.error("Error in addSeenTag:", e.message);
    }
};

export default addSeenTag;