// React
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

// Context
import { UserAuth } from "context/AuthContext";

// react-pwa-install
import { useReactPWAInstall } from "react-pwa-install";

// i18n
import { useTranslation } from "react-i18next";

// react-flagkit
import Flag from "react-flagkit";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// Components
import { InstallBanner } from "ui-components/PwaBanners";
import { LoginSuccessful } from "ui-components/ORFeedbacks";

// Firebase
import { requestPermission } from "config/firebase";

// Functions
import { seedFlow } from "SafeTwin/3S/seedFlow";
import addFCMToken from "user/addFCMToken";

// A ---------------------------------------------------------------------- M

// Custom component for language option
const LanguageOption = ({ country, label }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <Flag country={country} style={{ marginRight: "8px" }} />
    {label}
  </div>
);

const Signin = () => {
  const { user, setSeedFlow, signIn } = UserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  let tag;

  if (location.state && location.state.tag) {
    tag = location.state.tag;
  }

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  const [showInstallBanner, setShowInstallBanner] = useState(
    supported() && !isInstalled()
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [language, setLanguage] = useState("");
  const [invalidAccount, setInvalidAccount] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, []);

  const handleClick = () => {
    pwaInstall({
      title: t("install_pwa_title"),
      description: t("install_pwa_description"),
    })
      .then(() => console.log(t("installation_success")))
      .catch(() => console.log(t("installation_error")));
  };

  const handleSubmit = (e) => {
    signIn(email, password)
      .then(async (user) => {
        // FCM Token
        const token = await requestPermission();
        if (token) {
          await addFCMToken(user.user.uid, token);
        }

        // 3S Flow
        const isSeeded = await seedFlow(user.user, password);
        setSeedFlow(isSeeded);

        // Signin Successful
        setOpenSuccess(true);

        console.log("Signin Successful");
      })
      .catch((e) => {
        setPassword("");
        setInvalidAccount(true);
      });
  };

  const handleCloseSuccess = () => {
    console.log("Close Success");
    setOpenSuccess(false);
    if (tag) {
      navigate(`/${tag}`);
    } else {
      navigate("/");
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  return (
    <>
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            mt: isMobile ? 15 : 30,
            p: isMobile ? 2 : 3,
            boxShadow: 3,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant={isMobile ? "h6" : "h5"} mb="5%">
            {t("welcome")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                required
                fullWidth
                id="email"
                label={t("email")}
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                required
                fullWidth
                id="password"
                label={t("password")}
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            {invalidAccount && (
              <Grid item xs={12}>
                <Typography color="red">{t("email_password_wrong")}</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="language-label">
                  {t("select_language")}
                </InputLabel>
                <Select
                  labelId="language-label"
                  id="language-select"
                  label={t("select_language")}
                  value={language}
                  onChange={(e) => changeLanguage(e.target.value)}
                  renderValue={(selected) => {
                    if (selected === "en") {
                      return <LanguageOption country="GB" label="English" />;
                    } else if (selected === "it") {
                      return <LanguageOption country="IT" label="Italiano" />;
                    }
                    return null;
                  }}
                >
                  <MenuItem value="en">
                    <LanguageOption country="GB" label="English" />
                  </MenuItem>
                  <MenuItem value="it">
                    <LanguageOption country="IT" label="Italiano" />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" onClick={handleSubmit}>
                {t("sign_in")}
              </Button>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end">
              <Typography variant="body2">
                <Link to="/reset-password">{t("reset_password")}</Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <InstallBanner
        showInstallBanner={showInstallBanner}
        handleCloseInstallBanner={() => setShowInstallBanner(false)}
        handleClick={handleClick}
      />

      <LoginSuccessful
        open={openSuccess}
        handleCloseSuccess={handleCloseSuccess}
      />
    </>
  );
};

export default Signin;
