// Firebase
import { db } from 'config/firebase';
import { collection, setDoc, doc } from 'firebase/firestore';

// Functions
import unixTimestampInSeconds from 'utils/unixTimestampInSeconds';
import { genRndStringPrintable } from 'SafeTwin/crypto/cryptolibsodium';

// A ---------------------------------------------------------------------- M

const sendCertificationRequest = async (type, uid, isPublic, requestData, tdr) => {
    try {
        if (tdr) {
            const timestamp = unixTimestampInSeconds();

            const docID = genRndStringPrintable(20);

            // Set signature request record in recordsdata
            const recordRef = doc(db, 'recordsdata', docID);

            await setDoc(recordRef, {
                id: docID,
                type: type,
                version: 1,
                ...(tdr && { tdr: tdr }),
                creator_uuid: uid,
                data: requestData,
                public: isPublic,
                timestamp: timestamp,
            });

            // Set signature request in tagsdata/{tdr}/signature_requests
            const tagRef = doc(db, 'tagsdata', tdr);

            const collectionRef = collection(tagRef, 'signature_requests');

            const sigReqData = {
                id: docID,
                type: type,
                creator_uuid: uid,
                db_id: 1,
                public: isPublic,
                timestamp: timestamp,
                completed: false,
                tdr: tdr,
            };

            await setDoc(doc(collectionRef, docID), sigReqData);
        }
    } catch (e) {
        console.error('Error in sendCertificationRequest:', e.message);
    }
};

export default sendCertificationRequest;