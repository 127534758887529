// React
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Context
import { UserAuth } from "context/AuthContext";

// Material UI
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

// Icons
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

// Functions
import { seedFlow } from 'SafeTwin/3S/seedFlow';
import { decryptSeedByPasswordHash4Ed25519 } from 'SafeTwin/crypto/cryptoseed';
import quickSendCertificationStream from "generator/quickSendCertificationStream";

// A ---------------------------------------------------------------------- M

// ERRORS

// 0) CODE: 1000 | MESSAGE: LOADING_ERROR
// 1) CODE: 1001 | MESSAGE: INVALID_INPUT
// 2) CODE: 1002 | MESSAGE: AUTH_FAILED
// 3) CODE: 1003 | MESSAGE: INSUFFICIENT_PERMISSIONS
// 4) CODE: 1004 | MESSAGE: CERT_STREAM_FAILED
// 5) CODE: 1005 | MESSAGE: USER_REJECTED

const SimpleCertification = () => {
    const { setSeedFlow, signIn, logout, user } = UserAuth();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const hsh = queryParams.get('hsh');
    const tdr = queryParams.get('tdr');
    const permalogin = queryParams.get('permalogin');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [keypair, setKeypair] = useState('');
    const [showCertification, setShowCertification] = useState(false);
    const [showSignin, setShowSignin] = useState(false);
    const [invalidInput, setInvalidInput] = useState(false);
    const [invalidAccount, setInvalidAccount] = useState(false);
    const [insufficientPermissions, setInsufficientPermissions] = useState(false);
    const [openCertificationRejected, setOpenCertificationRejected] = useState(false);
    const [openCertificationSuccessful, setOpenCertificationSuccessful] = useState(false);
    const [openCertificationError, setOpenCertificationError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isProgress, setIsProgress] = useState(false);

    useEffect(() => {
        if (user) logout();

        if (hsh) setShowSignin(true);
        else {
            console.error("Error Code 1001 - INVALID_INPUT");
            setInvalidInput(true);
            window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1001', message: 'INVALID_INPUT' } }, '*');
        }

        setIsLoading(false);
    }, []);

    const handleSubmit = e => {
        e.preventDefault();
        signIn(email, password)
            .then(async (currentUser) => {
                setShowSignin(false);

                try {
                    const outcome = await seedFlow(currentUser.user, password);
                    setSeedFlow(outcome);

                    if (outcome) {
                        const keypair = decryptSeedByPasswordHash4Ed25519(currentUser.user.reloadUserInfo.passwordHash);

                        if (keypair) {
                            setKeypair(keypair);
                            setShowCertification(true);
                        } else {
                            console.error("Error Code 1003 - INSUFFICIENT_PERMISSIONS");
                            setInsufficientPermissions(true);
                            window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1003', message: 'INSUFFICIENT_PERMISSIONS' } }, '*');
                        }
                    } else {
                        console.error("Error Code 1003 - INSUFFICIENT_PERMISSIONS");
                        setInsufficientPermissions(true);
                        window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1003', message: 'INSUFFICIENT_PERMISSIONS' } }, '*');
                    }
                } catch (e) {
                    console.error("Error Code 1003 - INSUFFICIENT_PERMISSIONS");
                    setInsufficientPermissions(true);
                    window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1003', message: 'INSUFFICIENT_PERMISSIONS' } }, '*');
                }
            }).catch((e) => {
                console.error("Error Code 1002 - AUTH_FAILED");
                setPassword('');
                setInvalidAccount(true);
                window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1002', message: 'AUTH_FAILED' } }, '*');
            });
    };

    const handleCertify = async () => {
        try {
            setShowCertification(false)
            setIsProgress(true);

            const QuickSignData = await quickSendCertificationStream(keypair, hsh, tdr);

            if (QuickSignData) {
                console.log(QuickSignData);
                setOpenCertificationSuccessful(true);
                window.parent.postMessage({ type: 'QuickSignData', QuickSignData }, '*');
            } else {
                console.error("Error Code 1004 - CERT_STREAM_FAILED");
                setOpenCertificationError(true);
                window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1004', message: 'CERT_STREAM_FAILED' } }, '*');
            }

            setIsProgress(false);
        } catch (e) {
            setIsProgress(false);
            console.error("Error Code 1004 - CERT_STREAM_FAILED");
            setOpenCertificationError(true);
            window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1004', message: 'CERT_STREAM_FAILED' } }, '*');
        }
    };

    const handleReject = () => {
        console.error("Error Code 1005 - USER_REJECTED");
        setShowCertification(false);
        setOpenCertificationRejected(true);
        window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1005', message: 'USER_REJECTED' } }, '*');
    };

    return (
        <>
            {isLoading &&
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            }

            {invalidInput &&
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <ErrorIcon sx={{ fontSize: 100, color: 'red' }} />
                    <br />
                    <Typography variant="h6">Invalid input: 'hsh' field is missing</Typography>
                </Box>
            }

            {insufficientPermissions &&
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <ErrorIcon sx={{ fontSize: 100, color: 'red' }} />
                    <br />
                    <Typography variant="h6">Insufficient Permissions: you are not allowed to certify.</Typography>
                </Box>
            }

            <Dialog open={showSignin} fullScreen>
                <DialogTitle align="center">Enter your credentials</DialogTitle>
                <Divider />
                <DialogContent>
                    <Box component="form" onSubmit={handleSubmit} noValidate>
                        {!permalogin && <Alert severity="warning">You will not stay logged in on this device.</Alert>}
                        <TextField
                            margin="dense"
                            id="Email Address"
                            label="Email Address"
                            name="Email Address"
                            required
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            id="password"
                            label="Password"
                            name="Password"
                            type="password"
                            required
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {invalidAccount && <Typography color='red'>Email address or password wrong. Try again.</Typography>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                        >
                            Sign In
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={showCertification} fullScreen>
                <DialogTitle align="center">New Certification</DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography variant='body1' color='gray' gutterBottom>Certification Details</Typography>
                    <List disablePadding>
                        <ListItem disablePadding>
                            <ListItemText primary={<Typography sx={{ wordBreak: 'break-all' }}><b>Hash</b><br />{hsh}</Typography>} />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemText primary={<Typography sx={{ wordBreak: 'break-all' }}><b>Context</b><br />{tdr ? tdr : <i>Not provided</i>}</Typography>} />
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleReject} sx={{ bgcolor: 'white', color: 'red' }}>Reject</Button>
                    <Button variant="contained" onClick={handleCertify}>Certify</Button>
                </DialogActions>
            </Dialog>

            {openCertificationRejected &&
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <ErrorIcon sx={{ fontSize: 100, color: 'red' }} />
                    <br />
                    <Typography variant="h6">Certification Rejected</Typography>
                </Box>
            }

            {isProgress &&
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <CircularProgress />
                    <br />
                    <Typography variant="h6">Certification In Progress...</Typography>
                </Box>
            }

            {openCertificationSuccessful &&
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <CheckCircleIcon style={{ fontSize: 100, color: '#00FF00' }} />
                    <br />
                    <Typography variant="h6">Certification Successful</Typography>
                </Box>
            }

            {openCertificationError &&
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <ErrorIcon style={{ fontSize: 100, color: 'red' }} />
                    <br />
                    <Typography variant="h6">Certification Failed</Typography>
                </Box>
            }
        </>
    );
};

export default SimpleCertification;