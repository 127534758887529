// React
import React, { useState, useEffect } from 'react';

// Context
import { UserAuth } from 'context/AuthContext';

// Material UI
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import { DataboxCreationSuccessfulSnackbar } from 'ui-components/ORFeedbacks';
import DataBoxCreate from 'ui-components/DataBoxCreate';
import DataBoxesTable from 'ui-components/DataBoxesTable';

// Firebase
import { db } from 'config/firebase';
import { doc, collection, onSnapshot, query, where } from 'firebase/firestore';

// Functions
import fetchSeenTags from 'user/fetchSeenTags';
import getTagInfo from 'tag/getTagInfo';

// A ---------------------------------------------------------------------- M

const MyDataboxes = () => {
    const { user, canCreateDatabox, conservSostL1 } = UserAuth();

    const [seentags, setSeenTags] = useState([]);
    const [openGen, setOpenGen] = useState(false);
    const [openDataboxCreationSuccessful, setOpenDataboxCreationSuccessful] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getSeenTags = async () => {
            if (user) {
                const seentags = await fetchSeenTags(user.uid);
                const seentagsWithNames = await Promise.all(seentags.map(async (tag) => {
                    const tagInfo = await getTagInfo(tag.id);
                    const name = tagInfo ? (tagInfo.name || 'N/A') : 'N/A';
                    return { ...tag, name };
                }));

                seentagsWithNames.sort((tag1, tag2) => tag2.firstseen - tag1.firstseen);
                setSeenTags(seentagsWithNames);
            }
        };

        const fetchData = async () => {
            await getSeenTags();
            setIsLoading(false);
        };

        fetchData();

        const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
        const userdataDocRef = doc(db, 'userdata', user.uid);
        const seentagsCollectionRef = collection(userdataDocRef, 'seentags');

        const newAdditionsQueryTags = query(
            seentagsCollectionRef,
            where('firstseen', '>', currentTimestampInSeconds.toString())
        );

        const newAdditionsUnsubscribeTags = onSnapshot(newAdditionsQueryTags, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === 'added') {
                    getSeenTags();
                }
            });
        });

        return () => {
            newAdditionsUnsubscribeTags();
        };
    }, [user]);

    const openTagGen = () => {
        setOpenGen(true);
        window.history.pushState(null, '');
    };

    const handleOpenDataboxCreationSuccessful = () => {
        setOpenDataboxCreationSuccessful(true);
        window.history.pushState(null, '');
    };

    return (
        <>
            {isLoading ?
                <Grid item container justifyContent='center' mt='30%'>
                    <CircularProgress />
                </Grid> :
                <Grid item xs={12}>
                    <DataBoxesTable
                        tags={seentags}
                        handleOpen={openTagGen}
                    />
                </Grid>
            }

            {(canCreateDatabox || conservSostL1) &&
                <>
                    <DataBoxCreate open={openGen} setOpen={setOpenGen} handleSuccessful={handleOpenDataboxCreationSuccessful} />
                    <DataboxCreationSuccessfulSnackbar open={openDataboxCreationSuccessful} setOpen={setOpenDataboxCreationSuccessful} />
                </>
            }
        </>
    );
};

export default MyDataboxes;