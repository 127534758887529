// React
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Context
import { UserAuth } from "context/AuthContext";

// i18n
import { useTranslation } from "react-i18next";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// Material UI Components
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

// Components
import { LinearLoadingComponent } from "ui-components/LoadingComponent";
import { Puller } from "ui-components/Puller";
import {
  NewCertConfirm,
  NewRequestConfirm,
  InvalidFile,
} from "ui-components/ORFeedbacks";

// Functions
import { decryptSeedByPasswordHash4Ed25519 } from "SafeTwin/crypto/cryptoseed";
import genDocRecord from "generator/DocGenerator/genDocRecord";
import sendCertificationRequest from "tag/sendCertificationRequest";
import completeCertificationRequest from "tag/completeCertificationRequest";
import fileToUint8Array from "utils/fileToUint8Array";
import uploadFile from "storage/uploadFile";
import getFile from "storage/getFile";

// A ---------------------------------------------------------------------- M

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(() => ({
  input: {
    display: "none",
  },
}));

const DocGenerator = ({
  tags,
  tag,
  types,
  setTypes,
  open,
  setOpen,
  handleOpenCertificationSuccessful,
  handleOpenCertificationError,
  request,
  setRequest,
}) => {
  const { user } = UserAuth();
  const location = useLocation();
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [keypair, setKeyPair] = useState(null);
  const [source, setSource] = useState("");
  const [blobURL, setBlobURL] = useState("");
  const [downloadURL, setDownloadUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [comment, setComment] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [tagSelectionOpen, setTagSelectionOpen] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [approval, setApproval] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidFile, setIsInvalidFile] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  useEffect(() => {
    const decrypt = () => {
      if (open) {
        const keypair = decryptSeedByPasswordHash4Ed25519(
          user.reloadUserInfo.passwordHash
        );
        setKeyPair(keypair);
      }
    };

    decrypt();
  }, [open]);

  useEffect(() => {
    if (request) {
      const downloadURL = request.data.downloadURL;
      const fileName = request.data.fileName;
      const fileType = request.data.fileType;

      const fetchFile = async () => {
        const bytearray = await getFile(downloadURL);
        setSource(bytearray);
      };

      fetchFile();
      setDownloadUrl(downloadURL);
      setFileName(fileName);
      setFileType(fileType);
      setComment(request.data.comment);
      setIsPublic(request.public);
    } else {
      setSource("");
      setDownloadUrl("");
      setFileName("");
      setFileType("");
      setComment("");
      setIsPublic(false);
    }
  }, [request]);

  const handleGenerate = async () => {
    setConfirm(false);
    setIsLoading(true);

    if (keypair) {
      try {
        let result;
        let downloadURL;
        let uploadID;

        if (request) {
          downloadURL = request.data.downloadURL;
        } else {
          const uploadFileResult = await uploadFile(
            source,
            fileName,
            fileType,
            tag
          );
          downloadURL = uploadFileResult.downloadURL;
          uploadID = uploadFileResult.uploadID;
        }

        if (downloadURL) {
          if (tag) {
            result = await genDocRecord(
              isPublic,
              user.uid,
              keypair,
              source,
              downloadURL,
              comment,
              tag
            );
          } else if (selectedTag) {
            result = await genDocRecord(
              isPublic,
              user.uid,
              keypair,
              source,
              downloadURL,
              comment,
              selectedTag
            );
          } else {
            result = await genDocRecord(
              isPublic,
              user.uid,
              keypair,
              source,
              downloadURL,
              comment
            );
          }

          if (result && result.txid) {
            if (request) {
              await completeCertificationRequest(tag, request.id);
            }

            handleOpenCertificationSuccessful();

            if (types && !types.includes("doc")) {
              setTypes([...types, "doc"]);
            }
          } else {
            handleOpenCertificationError();
          }
        } else {
          console.error("Invalid file format");
          setIsInvalidFile(true);
          resetFileInput();
        }
      } catch (error) {
        console.error("Error in handleGenerate:", error.message);
        handleOpenCertificationError();
      }
    } else {
      console.log("No keypair");
    }

    setIsLoading(false);
    handleReset();
  };

  const handleSendApprovalRequest = async () => {
    setApproval(false);
    setIsLoading(true);

    try {
      const downloadURL = await uploadFile(source, fileName, fileType, tag);

      if (downloadURL) {
        const requestData = {
          downloadURL: downloadURL,
          fileName: fileName,
          fileType: fileType,
          comment: comment,
        };

        const requestTag = tag || selectedTag;

        await sendCertificationRequest(
          "doc",
          user.uid,
          isPublic,
          requestData,
          requestTag
        );
      } else {
        handleOpenCertificationError();
      }
    } catch (error) {
      console.error("Error in handleSendApprovalRequest:", error);
      handleOpenCertificationError();
    }

    setIsLoading(false);
    handleReset();
  };

  const handleFileChange = async (target) => {
    if (target.files && target.files.length !== 0) {
      const file = target.files[0];
      const validExtensions = ["pdf"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const mimeType = file.type;

      if (
        validExtensions.includes(fileExtension) &&
        mimeType.includes("application/pdf")
      ) {
        const fileByteArray = await fileToUint8Array(file);
        const blobURL = URL.createObjectURL(file);
        setSource(fileByteArray);
        setBlobURL(blobURL);
        setFileName(file.name);
        setFileType(file.type);
      } else {
        console.error("Invalid file format");
        setIsInvalidFile(true);
        resetFileInput();
      }
    }
  };

  const resetFileInput = () => {
    const fileInput = document.getElementById("certify-pdf-button");
    if (fileInput) {
      fileInput.value = null;
    }
  };

  const handleReset = () => {
    setSource("");
    setBlobURL("");
    URL.revokeObjectURL(blobURL);
    setFileName("");
    setFileType("");
    setDownloadUrl("");
    setComment("");
    setSelectedTag("");
    setIsPublic(false);
    setIsInvalidFile(false);

    if (request) {
      setRequest();
    }

    setOpen(false);
    resetFileInput();
  };

  const handleChange = (e) => {
    setSelectedTag(e.target.value);
  };

  const handleOpen = () => {
    setTagSelectionOpen(true);
    window.history.pushState(null, "");
  };

  const handleClose = () => {
    setTagSelectionOpen(false);
  };

  const handleFileClick = (file) => {
    setSelectedFile(file);
  };

  const handleCloseDialog = () => {
    setSelectedFile(null);
    setCurrentPage(1);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const DocReportForm = (
    <Grid container spacing={2}>
      {request ? (
        <Grid item container alignItems="center">
          <Grid item xs={12} sm={0.5}>
            <Typography>{t("file")} *</Typography>
          </Grid>
          <Grid item xs={12} sm={11.5}>
            <Button onClick={() => handleFileClick(downloadURL)}>
              {fileName || t("selected_file")}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid item container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={1}>
            <Typography>{t("file")} *</Typography>
          </Grid>
          <Grid item xs={12} sm={11}>
            <input
              className={classes.input}
              id="certify-pdf-button"
              type="file"
              accept="application/pdf"
              disabled={request}
              onChange={(e) => handleFileChange(e.target)}
            />
            <label htmlFor="certify-pdf-button">
              <Button
                disabled={request}
                variant="contained"
                component="span"
                sx={{ width: isMobile ? "100%" : "30%" }}
              >
                {t("select_file")}
              </Button>
            </label>
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <Typography>{t("selected_file")}</Typography>
          </Grid>
          {source ? (
            <Grid item xs={12} sm={10.5}>
              <Button onClick={() => handleFileClick(blobURL)}>
                {fileName || t("selected_file")}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} sm={10.5}>
              <Typography>{t("no_file_selected")}</Typography>
            </Grid>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          fullWidth
          margin="dense"
          id="Comments"
          name="Comments"
          label={t("description")}
          variant="outlined"
          value={comment}
          disabled={request}
          onChange={(e) => setComment(e.target.value)}
        />
      </Grid>
      {location.pathname === "/my-certifications" && tags && (
        <Grid item xs={12}>
          <Typography gutterBottom>{t("databox")}</Typography>
          <FormControl fullWidth>
            <Select
              id="selected-tag-doc"
              value={selectedTag}
              displayEmpty
              onChange={handleChange}
              open={tagSelectionOpen}
              onOpen={handleOpen}
              onClose={handleClose}
              sx={{ width: isMobile ? "100%" : "50%" }}
            >
              <MenuItem value="">
                <Typography color="gray">{t("no_databox")}</Typography>
              </MenuItem>
              {tags.map((tag) => (
                <MenuItem key={tag.id} value={tag.id}>
                  {tag.nickname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={isPublic}
                disabled={request}
                onChange={(event) => setIsPublic(event.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={t("mark_as_public")}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={handleReset}
        onOpen={() => setOpen(true)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            height: "90%",
            borderTopLeftRadius: "4%",
            borderTopRightRadius: "4%",
          },
        }}
      >
        <Puller />
        <Box sx={{ p: "5%" }}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            mt="5%"
            mb="10%"
          >
            <Grid item>
              <Typography variant="h6" fontWeight="bold">
                {t("certify_document")}
              </Typography>
            </Grid>
          </Grid>
          {isLoading ? (
            <LinearLoadingComponent />
          ) : (
            <>
              {DocReportForm}
              <Grid container spacing={1} mt="5%">
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={isLoading || !source}
                    onClick={() => setConfirm(true)}
                  >
                    {t("certify")}
                  </Button>
                </Grid>
                {!request && (
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={
                        isLoading ||
                        (location.pathname === "/my-certifications" &&
                          (!source || !selectedTag)) ||
                        (location.pathname !== "/my-certifications" && !source)
                      }
                      onClick={() => setApproval(true)}
                    >
                      {t("send_approval_request")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Box>
      </SwipeableDrawer>
      <NewCertConfirm
        confirm={confirm}
        setConfirm={setConfirm}
        handleGenerate={handleGenerate}
      />
      <NewRequestConfirm
        approval={approval}
        setApproval={setApproval}
        handleSendApprovalRequest={handleSendApprovalRequest}
      />
      <InvalidFile open={isInvalidFile} setOpen={setIsInvalidFile} />
      {selectedFile && (
        <Dialog
          open={Boolean(selectedFile)}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="lg"
        >
          <IconButton
            sx={{
              color: "red",
              justifyContent: "flex-end",
            }}
            onClick={handleCloseDialog}
          >
            <CloseOutlinedIcon />
          </IconButton>
          <Document
            file={selectedFile}
            onLoadSuccess={handleDocumentLoadSuccess}
          >
            <Page
              pageNumber={currentPage}
              width={isMobile ? "300" : "600"}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
            <Typography sx={{ mx: 2 }}>
              {currentPage} / {numPages}
            </Typography>
            <IconButton
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === numPages}
            >
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
          </Box>
        </Dialog>
      )}
    </>
  ) : (
    <>
      <Dialog open={open} onClose={handleReset} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                {t("certify_document")}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleReset}
                edge="end"
                sx={{ color: "red" }}
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {isLoading ? (
          <LinearLoadingComponent />
        ) : (
          <DialogContent dividers>
            {DocReportForm}
            <Grid item container xs={12} spacing={1} mt="3%">
              <Grid item xs={12} sm={!request ? 6 : 12}>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={isLoading || !source}
                  onClick={() => setConfirm(true)}
                >
                  {t("certify")}
                </Button>
              </Grid>
              {!request && (
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={
                      isLoading ||
                      (location.pathname === "/my-certifications" &&
                        (!source || !selectedTag)) ||
                      (location.pathname !== "/my-certifications" && !source)
                    }
                    onClick={() => setApproval(true)}
                  >
                    {t("send_approval_request")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </DialogContent>
        )}
      </Dialog>
      <NewCertConfirm
        confirm={confirm}
        setConfirm={setConfirm}
        handleGenerate={handleGenerate}
      />
      <NewRequestConfirm
        approval={approval}
        setApproval={setApproval}
        handleSendApprovalRequest={handleSendApprovalRequest}
      />
      <InvalidFile open={isInvalidFile} setOpen={setIsInvalidFile} />
      {selectedFile && (
        <Dialog
          open={Boolean(selectedFile)}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="lg"
        >
          <IconButton
            sx={{
              color: "red",
              justifyContent: "flex-end",
            }}
            onClick={handleCloseDialog}
          >
            <CloseOutlinedIcon />
          </IconButton>
          <Document
            file={selectedFile}
            onLoadSuccess={handleDocumentLoadSuccess}
          >
            <Page
              pageNumber={currentPage}
              width={isMobile ? "300" : "600"}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
            <Typography sx={{ mx: 2 }}>
              {currentPage} / {numPages}
            </Typography>
            <IconButton
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === numPages}
            >
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default DocGenerator;
