// Firebase
import { db } from "config/firebase";
import { doc, deleteDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

/**
 * Removes an authorized key from the 'authorized_keys' subcollection of a specified 'tagsdata' document.
 * 
 * @param {string} tagID - The ID of the tag from which the authorized key is being removed.
 * @param {string} keyID - The ID of the authorized key document to be deleted.
 * @returns {Promise<void>} A promise that resolves when the document is successfully deleted.
 */
const removeAuthorizedKey = async (tagID, keyID) => {
    try {
        // Create a reference to the document in the 'authorized_keys' subcollection
        const authorizedKeyRef = doc(db, `tagsdata/${tagID}/authorized_keys`, keyID);

        // Delete the specified document
        await deleteDoc(authorizedKeyRef);
    } catch (e) {
        console.error("Error in removeAuthorizedKey:", e.message);
    }
};

export default removeAuthorizedKey;
