// Firebase
import { db } from 'config/firebase';
import { doc, getDoc } from 'firebase/firestore';

// Functions
import getUserContacts from 'user/getUserContacts';

// A ---------------------------------------------------------------------- M

const fetchRequest = async (id) => {
    try {
        const reqRef = doc(db, "recordsdata", id);
        const req = await getDoc(reqRef);

        if (req.exists()) {
            const data = req.data();
            const creator_uuid = data.creator_uuid || "N/A";

            let creator;

            if (creator_uuid) {
                try {
                    const creatorContacts = await getUserContacts(creator_uuid);
                    if (creatorContacts.email) {
                        creator = creatorContacts.email;
                    } else {
                        creator = creator_uuid;
                    }
                } catch (e) {
                    console.error('Unable to fetch creator:', e.message);
                }
            } else {
                creator = "N/A";
            }

            return {
                id,
                creator_email: creator,
                ...data,
            };
        } else {
            return null;
        }
    } catch (e) {
        console.error('Error in fetchRequest:', e.message);
        return null;
    }
};

export default fetchRequest;