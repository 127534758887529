// Firebase
import { db } from "config/firebase";
import { collection, setDoc, doc, getDoc } from "firebase/firestore";

// Functions
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const addViewGroup = async (tagID, groupID) => {
    try {
        const tagRef = doc(db, 'tagsdata', tagID);
        const viewgroupsRef = collection(tagRef, 'viewgroups');
        const newViewGroupRef = doc(viewgroupsRef, groupID);
        const newViewGroupRefSnapshot = await getDoc(newViewGroupRef);

        if (!newViewGroupRefSnapshot.exists()) {
            await setDoc(newViewGroupRef, {
                group_id: groupID,
                added_on: unixTimestampInSeconds()
            });

            return { code: 0 };
        } else {
            return { code: -1 };
        }
    } catch (e) {
        console.error("Error in addViewGroup:", e.message);
    }
};

export default addViewGroup;