// Material UI
import { createTheme } from '@mui/material/styles';

// A ---------------------------------------------------------------------- M

export const DefaultTheme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        }
    },
    typography: {
        fontFamily: 'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    },
});

export const ArmilisTheme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        }
    },
    typography: {
        fontFamily: 'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    },
});

export const AMGTheme = createTheme({
    palette: {
        primary: {
            main: '#b2102f',
        },
        secondary: {
            main: '#f50057',
        }
    },
    typography: {
        fontFamily: 'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    },
});

export const AlsaTheme = createTheme({
    palette: {
        primary: {
            main: '#054e77',
        },
        secondary: {
            main: '#f50057',
        }
    },
    typography: {
        fontFamily: 'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    },
});

export const _jZP9Zja79jTheme = createTheme({
    palette: {
        primary: {
            main: '#054e77',
        },
        secondary: {
            main: '#f50057',
        }
    },
    typography: {
        fontFamily: 'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    },
});