// React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// Context
import { UserAuth } from "context/AuthContext";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// Icons
import FirstPageOutlinedIcon from "@mui/icons-material/FirstPageOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import LastPageOutlinedIcon from "@mui/icons-material/LastPageOutlined";
import AddIcon from "@mui/icons-material/Add";

// Components
import { GroupRemoveConfirm } from "./ORFeedbacks";

// Functions
import getTagOwner from "tag/getTagOwner";
import removeViewGroup from "tag/removeViewGroup";
import removeViewGroupReadOnly from "tag/removeViewGroupReadOnly";
import convertTimestamp from "utils/convertTimestamp";

// ----------------------------------------------------------------------

const ViewGroupsTableHead = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const headCells = [
    { id: "name", label: t("name"), mobileLabel: t("name") },
    {
      id: "timestamp",
      label: t("creation_datetime"),
      mobileLabel: t("creation_datetime"),
    },
    {
      id: "permissions",
      label: t("permissions"),
      mobileLabel: t("permissions"),
    },
    { id: "actions", label: t("actions"), mobileLabel: t("actions") },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          (headCell) =>
            (!isMobile ||
              headCell.id === "name" ||
              headCell.id === "timestamp" ||
              headCell.id === "permissions" ||
              headCell.id === "actions") && (
              <TableCell
                key={headCell.id}
                align={headCell.id !== "actions" ? "left" : "right"}
              >
                {isMobile ? headCell.mobileLabel : headCell.label}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
};

const ViewGroupsTableToolbar = ({ openAddViewGroupDrawer, tagOwner }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6}>
        <Typography variant="h6">{t("authorized_groups")}</Typography>
      </Grid>
      {tagOwner === user.uid && (
        <Grid item container xs={12} sm={12} md={6} justifyContent="flex-end">
          <Grid item xs={12} sm={12} md={4}>
            <Button
              variant="contained"
              fullWidth
              onClick={openAddViewGroupDrawer}
              startIcon={<AddIcon />}
            >
              {t("add_group")}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const TablePaginationActions = (props) => {
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <LastPageOutlinedIcon />
        ) : (
          <FirstPageOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRightOutlinedIcon />
        ) : (
          <KeyboardArrowLeftOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeftOutlinedIcon />
        ) : (
          <KeyboardArrowRightOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageOutlinedIcon />
        ) : (
          <LastPageOutlinedIcon />
        )}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ViewGroupsTable = ({ tag, groups, openAddViewGroupDrawer }) => {
  const { conservSostL1 } = UserAuth();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tagOwner, setTagOwner] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [showRemove, setShowRemove] = useState(false);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - groups.length) : 0;

  useEffect(() => {
    const fetchTagOwner = async () => {
      const tagOwner = await getTagOwner(tag.id);
      setTagOwner(tagOwner);
    };

    fetchTagOwner();
  }, [tag]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFirstPageButtonClick = () => {
    setPage(0);
  };

  const handleBackButtonClick = () => {
    setPage(page - 1);
  };

  const handleNextButtonClick = () => {
    setPage(page + 1);
  };

  const handleLastPageButtonClick = () => {
    setPage(Math.max(0, Math.ceil(groups.length / rowsPerPage) - 1));
  };

  const handleShowRemove = (group) => {
    setShowRemove(true);
    setSelectedGroup(group);
  };

  const handleRemoveGroup = async () => {
    try {
      if (selectedGroup.permissions === "r/w") {
        await removeViewGroup(tag.id, selectedGroup.id);
      } else if (selectedGroup.permissions === "r") {
        await removeViewGroupReadOnly(tag.id, selectedGroup.id);
      }
    } catch (error) {
      console.error("Error in handleRemoveGroup:", error.message);
    } finally {
      setShowRemove(false);
    }
  };

  return (
    <>
      <Box>
        <ViewGroupsTableToolbar
          openAddViewGroupDrawer={openAddViewGroupDrawer}
          tagOwner={tagOwner}
        />
        <TableContainer>
          <Table>
            <ViewGroupsTableHead />
            <TableBody>
              {(rowsPerPage > 0
                ? groups.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : groups
              ).map((group) => {
                return (
                  <TableRow
                    key={group.id}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    <TableCell align="left">{group.name || "N/A"}</TableCell>
                    <TableCell align="left">
                      {group.added_on
                        ? convertTimestamp(group.added_on)
                        : "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {group.permissions === "r/w" ? "ALL" : "READ-ONLY"}
                    </TableCell>
                    <TableCell align="right" size="small">
                      <Button
                        onClick={() => navigate(`/${group.id}/members`)}
                        size="small"
                      >
                        {t("members")}
                      </Button>
                      <Button
                        onClick={() => navigate(`/${group.id}/databoxes`)}
                        size="small"
                      >
                        {!conservSostL1 ? "Databoxes" : "Archives"}
                      </Button>
                      <Button
                        sx={{ color: "red" }}
                        onClick={() => handleShowRemove(group)}
                        size="small"
                      >
                        {t("remove")}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
            {!isMobile && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, { label: "All", value: -1 }]}
                    colSpan={4}
                    count={groups.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
          {isMobile && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
              >
                <FirstPageOutlinedIcon />
              </IconButton>
              <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
                <KeyboardArrowLeftOutlinedIcon />
              </IconButton>
              <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(groups.length / rowsPerPage) - 1}
              >
                <KeyboardArrowRightOutlinedIcon />
              </IconButton>
              <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(groups.length / rowsPerPage) - 1}
              >
                <LastPageOutlinedIcon />
              </IconButton>
            </Box>
          )}
        </TableContainer>
      </Box>
      <GroupRemoveConfirm
        showRemove={showRemove}
        setShowRemove={setShowRemove}
        handleRemoveGroup={handleRemoveGroup}
      />
    </>
  );
};

export default ViewGroupsTable;
