// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from 'firebase/firestore';

// A ---------------------------------------------------------------------- M

/**
 * Checks if a user is authorized for a specific databox.
 * This includes checking if the user is explicitly authorized or is the owner of the databox.
 * 
 * @param {string} tagID - The ID of the databox to check permissions for.
 * @param {string} userID - The ID of the user to check authorization.
 * @returns {Promise<boolean>} - A promise that resolves to true if the user is authorized for the databox (either as an authorized user or as the owner), false otherwise.
 * 
 * 
 */
const isUserAuthorizedForDataBox = async (tagID, userID) => {
    try {
        const tagsDataRef = doc(db, `tagsdata/${tagID}`);
        const tagsDataSnap = await getDoc(tagsDataRef);

        if (tagsDataSnap.exists() && tagsDataSnap.data().owner_uuid === userID) {
            return true;
        }

        const userRef = doc(db, `tagpermissions/${tagID}/authorized_users/${userID}`);
        const userSnap = await getDoc(userRef);
        
        return userSnap.exists();
    } catch (e) {
        console.error("Error in checking user authorization for databox: ", e.message);
        return false;
    }
};

export default isUserAuthorizedForDataBox;