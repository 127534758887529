// Firebase
import { db } from 'config/firebase';
import { doc, getDoc } from 'firebase/firestore';

// A ---------------------------------------------------------------------- M

/**
 * Retrieves a tag general information from Firestore.
 * 
 * This function fetches the tag data from a Firestore document based on the provided `tagID`.
 * The function returns an object containing the tag's data along with its associated targets.
 * 
 * @param {string} tagID - The ID of the tag to retrieve from Firestore.
 * @returns {Promise<Object>} An object containing the tag's data.
 * If the tag doesn't exist, null is returned. In case of an error, it returns null and logs the error message to the console.
 */
const getTagInfo = async (tagID) => {
    try {
        const tagRef = doc(db, 'tagsdata', tagID);
        const tagSnapshot = await getDoc(tagRef);

        if (tagSnapshot.exists()) {
            const tagData = tagSnapshot.data();
            return {
                id: tagSnapshot.id,
                ...tagData,
            };
        }

        return null;
    } catch (e) {
        console.error("Error in getTagInfo:", e.message);
        return null;
    }
};

export default getTagInfo;