// Firebase
import { db } from "config/firebase";
import { doc, setDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

/**
 * Creates a databox in the Firestore database under 'tagsdata' collection.
 *
 * @param {string} tag - The identifier for the databox, used as the document key in the database.
 * @param {string} uuid - The UUID of the databox owner.
 * @param {string} name - The databox name
 * @returns {Promise<void>} A promise that resolves if the operation is successful, and rejects if an error occurs.
 */
const createDataBox = async (tag, uuid, name) => {
	try {
		const tagRef = doc(db, 'tagsdata', tag);

		await setDoc(tagRef, {
			owner_uuid: uuid,
			name: name,
			types: [],
			followers: [],
		});
	} catch (e) {
		console.error("Error in createDataBox:", e.message);
	}
};

export default createDataBox;
