import { doc, getDoc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "config/firebase";

const addFCMToken = async (uid, fcm_token) => {
    try {
        const userRef = doc(db, 'fcm_tokens', uid);
        const userDoc = await getDoc(userRef);

        if (!userDoc.exists()) {
            await setDoc(userRef, {
                fcm_tokens: [fcm_token],
            });
        } else {
            if (userDoc.data() && userDoc.data().fcm_tokens && !userDoc.data().fcm_tokens.includes(fcm_token)) {
                await updateDoc(userRef, {
                    fcm_tokens: arrayUnion(fcm_token)
                });
            } else {
                await updateDoc(userRef, {
                    fcm_tokens: arrayUnion(fcm_token)
                });
            }
        }

    } catch (e) {
        console.error('Add FCM token failed:', e.message);
    }
};

export default addFCMToken;