import { db } from 'config/firebase';
import { doc, updateDoc } from 'firebase/firestore';

// A ---------------------------------------------------------------------- M

/**
 * Updates or adds the public key URL for a given user UUID in Firestore.
 * @param {string} uuid - The UUID of the user.
 * @param {string} pubKeyUrl - The public key URL to be set.
 * @returns {Promise<void>} A promise that resolves when the update is complete.
 */
const addPubKeyUrl = async (uuid, pubKeyUrl) => {
    try {
        const userRef = doc(db, 'usercontacts', uuid);

        await updateDoc(userRef, {
            pubkeyurl: pubKeyUrl
        });
    } catch (e) {
        console.error('Error in addPubKeyUrl:', e.message);
    }
};

export default addPubKeyUrl;