// Firebase
import { db } from "config/firebase";
import { doc, updateDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

/**
 * Sets the canCreateDatabox flag to true for the specified keyID document.
 * @param {string} keyID - The ID of the document to update.
 */
const setCanCreateDataboxFlag = async (keyID) => {
  try {
    const docRef = doc(db, "keysecrets", keyID);
    await updateDoc(docRef, {
      canCreateDatabox: true,
    });
    console.log(`Document with ID ${keyID} successfully updated.`);
  } catch (e) {
    console.error("Error in setCanCreateDataboxFlag:", e.message);
  }
};

export default setCanCreateDataboxFlag;
