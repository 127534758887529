// Firebase
import { db } from "config/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const addFollower = async (uid, tag) => {
    try {
        const tagDocRef = doc(db, "tagsdata", tag);
        const tagDocSnapshot = await getDoc(tagDocRef);

        if (tagDocSnapshot.exists()) {
            const tagData = tagDocSnapshot.data();
            const followersArray = tagData.followers || [];

            if (!followersArray.includes(uid)) {
                followersArray.push(uid);
                await updateDoc(tagDocRef, { followers: followersArray });
            }
        }
    } catch (e) {
        console.error("Error in addFollower", e.message);
    }
};

export default addFollower;
