import { doc, collection, getDoc } from "firebase/firestore";
import { db } from "config/firebase";

const getSeenTag = async (uid, tagID) => {
  try {
    const userRef = doc(db, "userdata", uid);
    const seentagsRef = collection(userRef, "seentags");
    const seentagRef = doc(seentagsRef, tagID);
    const seentag = await getDoc(seentagRef);

    return {
      id: seentag.id,
      ...seentag.data(),
    };
  } catch (e) {
    console.error("Get seentag failed: ", e.message);
  }
};

export default getSeenTag;
