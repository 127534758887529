// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Context
import { UserAuth } from "context/AuthContext";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

// Icons
import FirstPageOutlinedIcon from "@mui/icons-material/FirstPageOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import LastPageOutlinedIcon from "@mui/icons-material/LastPageOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AddIcon from "@mui/icons-material/Add";

// Functions
import convertTimestamp from "utils/convertTimestamp";
import { t } from "i18next";

// A ---------------------------------------------------------------------- M

const ActivatedPubKeyTableHead = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const headCells = [
    { id: "name", label: t("name"), mobileLabel: t("name") },
    {
      id: "timestamp",
      label: t("activation_datetime"),
      mobileLabel: t("activation_datetime"),
    },
    { id: "company", label: t("company"), mobileLabel: t("company") },
    { id: "type", label: t("type"), mobileLabel: t("type") },
    { id: "publickey", label: t("public_key"), mobileLabel: t("public_key") },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left">
            {isMobile ? headCell.mobileLabel : headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const ActivatedPubKeyTableToolbar = ({ handleOpen }) => {
  const { isReseller } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Grid container p={!isMobile ? "1%" : "2%"} spacing={1}>
      <Grid item xs={12} sm={6} md={4}>
        <Typography variant="h6">
          {t("activated_public_keys_service_level_2")}
        </Typography>
      </Grid>
      <Grid container item xs={12} sm={12} md={8} justifyContent="flex-end">
        {isReseller && (
          <Grid item>
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{ height: "100%" }}
              size={isMobile ? "small" : "medium"}
              startIcon={<AddIcon />}
            >
              {t("activate_public_key")}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const DataRow = ({ publicKeyData }) => {
  const [show, setShow] = useState(false);

  const toggleVisibility = () => {
    setShow(!show);
  };

  return (
    <TableRow sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}>
      <TableCell
        align="left"
        sx={{
          minWidth: "90px",
          maxWidth: "90px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {publicKeyData.name || "N/A"}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          minWidth: "90px",
          maxWidth: "90px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {publicKeyData.added_on
          ? convertTimestamp(publicKeyData.added_on)
          : "N/A"}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          minWidth: "90px",
          maxWidth: "90px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {publicKeyData.company || "N/A"}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          minWidth: "90px",
          maxWidth: "90px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {publicKeyData.type || "N/A"}
      </TableCell>
      <TableCell align="left" sx={{ minWidth: "180px", maxWidth: "180px" }}>
        <span style={{ marginRight: "10px" }}>
          {show ? publicKeyData.publicKey : "••••••"}
        </span>
        <Tooltip title={show ? t("hide") : t("show")}>
          <IconButton onClick={toggleVisibility} size="small">
            {show ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const TablePaginationActions = (props) => {
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <LastPageOutlinedIcon />
        ) : (
          <FirstPageOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRightOutlinedIcon />
        ) : (
          <KeyboardArrowLeftOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeftOutlinedIcon />
        ) : (
          <KeyboardArrowRightOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageOutlinedIcon />
        ) : (
          <LastPageOutlinedIcon />
        )}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function ActivatedPubKeyTable({ publicKeysData, handleOpen }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - publicKeysData.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFirstPageButtonClick = () => {
    setPage(0);
  };

  const handleBackButtonClick = () => {
    setPage(page - 1);
  };

  const handleNextButtonClick = () => {
    setPage(page + 1);
  };

  const handleLastPageButtonClick = () => {
    setPage(Math.max(0, Math.ceil(publicKeysData.length / rowsPerPage) - 1));
  };

  return (
    <Box>
      <ActivatedPubKeyTableToolbar handleOpen={handleOpen} />
      <TableContainer>
        <Table>
          <ActivatedPubKeyTableHead />
          <TableBody>
            {(rowsPerPage > 0
              ? publicKeysData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : publicKeysData
            ).map((publicKeyData) => {
              return (
                <DataRow
                  key={publicKeyData.publicKey}
                  publicKeyData={publicKeyData}
                />
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={5}
                  count={publicKeysData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {isMobile && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
            >
              <FirstPageOutlinedIcon />
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={
                page >= Math.ceil(publicKeysData.length / rowsPerPage) - 1
              }
            >
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={
                page >= Math.ceil(publicKeysData.length / rowsPerPage) - 1
              }
            >
              <LastPageOutlinedIcon />
            </IconButton>
          </Box>
        )}
      </TableContainer>
    </Box>
  );
}
