// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Context
import { UserAuth } from "context/AuthContext";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";

// Icons
import FirstPageOutlinedIcon from "@mui/icons-material/FirstPageOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import LastPageOutlinedIcon from "@mui/icons-material/LastPageOutlined";
import AddIcon from "@mui/icons-material/Add";

// Components
import { MemberRemoveConfirm } from "./ORFeedbacks";

// Functions
import removeMember from "group/removeMember";

// A ---------------------------------------------------------------------- M

const MembersTableHead = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const headCells = [
    { id: "email", label: t("email"), mobileLabel: t("email") },
    { id: "role", label: t("role"), mobileLabel: t("role") },
    { id: "actions", label: t("actions"), mobileLabel: t("actions") },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          (headCell) =>
            (!isMobile ||
              headCell.id === "email" ||
              headCell.id === "role" ||
              headCell.id === "actions") && (
              <TableCell
                key={headCell.id}
                align={headCell.id !== "actions" ? "left" : "right"}
              >
                {isMobile ? headCell.mobileLabel : headCell.label}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
};

const MembersTableToolbar = ({ creator_uuid, setOpen }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6}></Grid>
      {user.uid === creator_uuid && (
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={6}
          spacing={2}
          justifyContent="flex-end"
        >
          <Grid item xs={12} sm={12} md={4}>
            <Button
              variant="contained"
              sx={{ height: "100%" }}
              fullWidth
              onClick={() => setOpen(true)}
              startIcon={<AddIcon />}
            >
              {t("add_member")}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const TablePaginationActions = (props) => {
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <LastPageOutlinedIcon />
        ) : (
          <FirstPageOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRightOutlinedIcon />
        ) : (
          <KeyboardArrowLeftOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeftOutlinedIcon />
        ) : (
          <KeyboardArrowRightOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageOutlinedIcon />
        ) : (
          <LastPageOutlinedIcon />
        )}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const MembersTable = ({
  group,
  members,
  setOpen,
  handleSuccessfulRemoved,
  handleErrorRemoved,
}) => {
  const { user } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectedMember, setSelectedMember] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [showRemove, setShowRemove] = useState(false);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - members.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFirstPageButtonClick = () => {
    setPage(0);
  };

  const handleBackButtonClick = () => {
    setPage(page - 1);
  };

  const handleNextButtonClick = () => {
    setPage(page + 1);
  };

  const handleLastPageButtonClick = () => {
    setPage(Math.max(0, Math.ceil(members.length / rowsPerPage) - 1));
  };

  const handleShowRemove = (memberUID, groupID) => {
    setShowRemove(true);
    setSelectedMember(memberUID);
    setSelectedGroup(groupID);
  };

  const handleRemoveMember = async () => {
    try {
      await removeMember(selectedMember, selectedGroup);
      setShowRemove(false);
      handleSuccessfulRemoved();
    } catch (error) {
      handleErrorRemoved();
    }
  };

  return (
    <>
      <Box>
        <MembersTableToolbar
          creator_uuid={group.creator_uuid}
          setOpen={setOpen}
        />
        <TableContainer>
          <Table>
            <MembersTableHead />
            <TableBody>
              {(rowsPerPage > 0
                ? members.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : members
              ).map((member) => {
                return (
                  <TableRow
                    key={member.uid}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        minWidth: "20%",
                        maxWidth: "20%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {member.email || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {member.uid === group.creator_uuid
                        ? t("admin")
                        : t("member")}
                    </TableCell>
                    <TableCell align="right" size="small">
                      {user.uid === group.creator_uuid &&
                      member.uid !== group.creator_uuid ? (
                        <Button
                          sx={{ height: "100%", color: "red" }}
                          onClick={() => handleShowRemove(member.uid, group.id)}
                          size="small"
                        >
                          {t("remove")}
                        </Button>
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={3} />
                </TableRow>
              )}
            </TableBody>
            {!isMobile && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                    colSpan={3}
                    count={members.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
          {isMobile && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
              >
                <FirstPageOutlinedIcon />
              </IconButton>
              <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
                <KeyboardArrowLeftOutlinedIcon />
              </IconButton>
              <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(members.length / rowsPerPage) - 1}
              >
                <KeyboardArrowRightOutlinedIcon />
              </IconButton>
              <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(members.length / rowsPerPage) - 1}
              >
                <LastPageOutlinedIcon />
              </IconButton>
            </Box>
          )}
        </TableContainer>
      </Box>

      <MemberRemoveConfirm
        showRemove={showRemove}
        setShowRemove={setShowRemove}
        handleRemoveMember={handleRemoveMember}
      />
    </>
  );
};

export default MembersTable;
