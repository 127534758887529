// Firebase
import { db } from "config/firebase";
import { collection, getDocs, doc } from 'firebase/firestore';

// A ---------------------------------------------------------------------- M

/**
 * Retrieves all documents from the 'forward_routes' subcollection 
 * of a specified document in the 'tagsdata' collection from Firebase Firestore.
 * 
 * @param {string} tagID - The unique identifier for a document in the 'tagsdata' collection.
 * @returns {Promise<Array<Object>>} A promise that resolves to an array of objects, 
 * each representing a document in the 'forward_routes' subcollection. Each object 
 * contains the document ID and its data. In case of an error, it returns an empty array.
 */
const getForwardRoutes = async (tagID) => {
    try {
        const tagRef = doc(db, 'tagsdata', tagID);
        const forwardRoutesRef = collection(tagRef, 'forward_routes');
        const forwardRoutes = await getDocs(forwardRoutesRef);
        return forwardRoutes.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (e) {
        console.error("Error in getForwardRoutes:", e.message);
        return [];
    }
};

export default getForwardRoutes;
