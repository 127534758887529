// React
import React, { useEffect, useState } from "react";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

// Functions
import fetchRecordsByType from "record/fetchRecordsByType";
import exportCSV from "utils/exportCSV";

// A ---------------------------------------------------------------------- M

const MpsExportCSV = ({ tag, open, handleClose, isTagGroupMember }) => {
  const { t } = useTranslation();

  const [uoms, setUoms] = useState([]);
  const [selectedUom, setSelectedUom] = useState("");
  const [startDate, setStartDate] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const fetch = async () => {
      const records = await fetchRecordsByType(
        tag,
        "mps",
        isTagGroupMember,
        startDate,
        endDate
      );
      const uomRecords = records.reduce((acc, record) => {
        const { uom } = record.data;
        acc[uom] = [...(acc[uom] || []), record].sort(
          (a, b) => a.data.timestamp - b.data.timestamp
        );
        return acc;
      }, {});

      if (Object.keys(uomRecords).length !== 0) {
        setUoms(Object.keys(uomRecords));
        setSelectedUom(Object.keys(uomRecords)[0]);
      } else {
        setUoms([]);
        setSelectedUom("");
      }
    };

    fetch();
  }, [tag, startDate, endDate]);

  const handleChange = (event) => {
    setSelectedUom(event.target.value);
  };

  const handleExportCSV = async () => {
    const records = await fetchRecordsByType(
      tag,
      "mps",
      isTagGroupMember,
      startDate,
      endDate,
      selectedUom
    );
    const csvArray = records.map((record) => {
      return {
        id: record.id,
        timestamp: record.data.timestamp,
        value: record.data.value,
        uom: record.data.uom,
      };
    });
    exportCSV(csvArray.reverse());
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle variant="h5">
        <b>{t("export_csv")}</b>
      </DialogTitle>
      <DialogContent>
        <Grid container mt="3%">
          <Grid item container xs={12} spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label={t("start_date")}
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label={t("end_date")}
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </LocalizationProvider>
            </Grid>
            {uoms.length !== 0 && (
              <Grid item xs={12} sm={4}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                  <FormLabel>{t("select_parameter")}</FormLabel>
                  <RadioGroup value={selectedUom} onChange={handleChange}>
                    {uoms.map((uom) => {
                      return (
                        <FormControlLabel
                          key={uom}
                          value={uom}
                          control={<Radio />}
                          label={uom}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          {t("cancel")}
        </Button>
        <Button
          disabled={selectedUom === ""}
          onClick={handleExportCSV}
          variant="contained"
        >
          {t("export")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MpsExportCSV;
