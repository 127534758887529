// React
import React, { useState, useEffect, forwardRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Context
import { UserAuth } from "context/AuthContext";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Drawer from "@mui/material/Drawer";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";

// Icons
import SearchOffOutlinedIcon from "@mui/icons-material/SearchOffOutlined";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

// Components
import { DrawerHeader } from "ui-components/DrawerHeader";
import {
  CertificationSuccessful,
  CertificationError,
  DataBoxFound,
} from "ui-components/ORFeedbacks";
import TagToolbar from "ui-components/TagToolbar";
import CertificationMenu from "ui-components/CertificationMenu";
import TagConfigure from "ui-components/TagConfigure";
import RequestsTable from "ui-components/RequestsTable";

// Viewers
import AggregatedViewer from "ui-components/viewers/AggregatedViewer/AggregatedViewer";
import DocViewer from "ui-components/viewers/DocViewer/DocViewer";
import ImageViewer from "ui-components/viewers/ImageViewer/ImageViewer";
import InfoViewer from "ui-components/viewers/InfoViewer/InfoViewer";

// Custom Viewers
import ConfigViewer from "ui-components/viewers/ConfigViewer/ConfigViewer";
import MpsViewer from "ui-components/viewers/MpsViewer/MpsViewer";
import SensorDataAggregatedViewer from "ui-components/viewers/SensorDataAggregatedViewer/SensorDataAggregatedViewer";
import CDNFileViewer from "ui-components/viewers/CDNFileViewer/CDNFileViewer";
import LikeSirtiViewer from "ui-components/viewers/Custom/LikeSirti/LikeSirtiViewer";

// Generators
import DocGenerator from "ui-components/generators/DocGenerator";
import ImageGenerator from "ui-components/generators/ImageGenerator";
import InfoGenerator from "ui-components/generators/InfoGenerator";
import CDNFileGenerator from "ui-components/generators/CDNFileGenerator/CDNFileGenerator";

// Custom Generators
import ConfigGenerator from "ui-components/generators/ConfigGenerator";

// Firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// Functions
import getTag from "tag/getTag";
import getTagOwner from "tag/getTagOwner";
import getTagTypes from "tag/getTagTypes";
import getTags from "tag/getTags";
import getDataboxRecordsCount from "tag/getDataboxRecordsCount";
import fetchSeenTags from "user/fetchSeenTags";
import fetchRequests from "record/fetchRequests";
import isUserAuthorizedForDataBox from "user/isUserAuthorizedForDataBox";
import isUserAuthorizedForDataBoxReadOnly from "user/isUserAuthorizedForDataBoxReadOnly";

// A ---------------------------------------------------------------------- M

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="outlined" {...props} />;
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const CompletenessCheck = ({ tag, recordsCount }) => {
  const isValid = tag === "E4qSNCkLyz";

  return (
    <Paper elevation={4} sx={{ p: "2%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" alignItems="center">
          {isValid ? (
            <>
              <CheckCircleIcon
                fontSize="large"
                sx={{ color: "green", mr: 1 }}
              />
              <Typography variant="h5" color="green" fontWeight="bold">
                Completeness Check Successful
              </Typography>
            </>
          ) : (
            <>
              <ErrorIcon fontSize="large" sx={{ color: "red", mr: 1 }} />
              <Typography variant="h5" color="red" fontWeight="bold">
                Completeness Check Error
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="h6"
            sx={{ color: isValid ? "green" : "red" }}
            fontWeight="bold"
          >
            {isValid ? recordsCount : recordsCount - 2}/{recordsCount} records
            found
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="h6">
            {isValid
              ? "No extra missing point with respect to certifications registry"
              : "Some missing points with respect to certifications registry"}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const Tag = () => {
  const { user, conservSostL1, canSign, seedflow } = UserAuth();
  const { tag } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [currentTag, setCurrentTag] = useState();
  const [recordsCount, setRecordsCount] = useState();
  const [requests, setRequests] = useState();
  const [request, setRequest] = useState();
  const [types, setTypes] = useState([]);
  const [seentag, setSeentag] = useState();
  const [tagExist, setTagExist] = useState(false);
  const [tagNotExist, setTagNotExist] = useState(false);
  const [tagConf, setTagConf] = useState(false);
  const [openDocGen, setOpenDocGen] = useState(false);
  const [openImageGen, setOpenImageGen] = useState(false);
  const [openInfoGen, setOpenInfoGen] = useState(false);
  const [openUploadGen, setOpenUploadGen] = useState(false);
  const [openConfigurationGen, setOpenConfigurationGen] = useState(false);
  const [openCertificationSuccessful, setOpenCertificationSuccessful] =
    useState(false);
  const [openCertificationError, setOpenCertificationError] = useState(false);
  const [isTagGroupMember, setIsTagGroupMember] = useState(false);
  const [isTagGroupMemberReadOnly, setIsTagGroupMemberReadOnly] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handlePopstate = () => {
      if (tagNotExist) {
        handleTagNotExist();
      } else if (tagConf) {
        handleCloseTagConf();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [tagNotExist, tagConf]);

  useEffect(() => {
    const fetchTagInfo = async () => {
      const isTagGroupMember = await isUserAuthorizedForDataBox(tag, user.uid);
      const isTagGroupMemberReadOnly = await isUserAuthorizedForDataBoxReadOnly(
        tag,
        user.uid
      );
      const currentTag = await getTag(tag);
      setIsTagGroupMember(isTagGroupMember);
      setIsTagGroupMemberReadOnly(isTagGroupMemberReadOnly);
      setCurrentTag(currentTag);
    };

    const fetchDataboxRecordsCount = async () => {
      const recordsCount = await getDataboxRecordsCount(tag);
      setRecordsCount(recordsCount);
    };

    const fetchTagRequests = async () => {
      const tagOwner = await getTagOwner(tag);
      if (user.uid === tagOwner) {
        const requests = await fetchRequests(tag);
        requests.sort((a, b) => b.timestamp - a.timestamp);
        setRequests(requests);
      }
    };

    const getTypes = async () => {
      const types = await getTagTypes(tag);
      const allTypes = [...new Set([...types, ...types])];
      setTypes(allTypes);
    };

    const tagFlow = async () => {
      const tagIDs = await getTags();

      if (tagIDs.includes(tag)) {
        const seentags = await fetchSeenTags(user.uid);
        const seentag = seentags.find((tagObj) => tagObj.id === tag);

        if (seentag) {
          const tagData = await getTag(tag);
          const tagName = tagData.name;
          setSeentag({ ...seentag, name: tagName });
          setTagExist(true);
          const tagsdataDocRef = doc(db, "tagsdata", tag);
          const unsubscribeTypesModified = onSnapshot(tagsdataDocRef, (doc) => {
            getTypes();
          });

          return () => {
            unsubscribeTypesModified();
          };
        } else {
          handleTagConfDrawer();
        }
      } else {
        handleTagNotExistDrawer();
      }
    };

    const fetchData = async () => {
      await fetchTagInfo();
      await fetchDataboxRecordsCount();
      await fetchTagRequests();
      await tagFlow();
      setIsLoading(false);
    };

    fetchData();

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    const tagsdataDocRef = doc(db, "tagsdata", tag);
    const signatureRequestsCollectionRef = collection(
      tagsdataDocRef,
      "signature_requests"
    );

    const newAddedQuerySignatureRequests = query(
      signatureRequestsCollectionRef,
      where("timestamp", ">", currentTimestampInSeconds.toString())
    );

    const newUnsubscribeAddedSignatureRequests = onSnapshot(
      newAddedQuerySignatureRequests,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            fetchTagRequests();
          }
        });
      }
    );

    const newUnsubscribeUpdatedSignatureRequests = onSnapshot(
      signatureRequestsCollectionRef,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "modified") {
            fetchTagRequests();
          }
        });
      }
    );

    return () => {
      newUnsubscribeAddedSignatureRequests();
      newUnsubscribeUpdatedSignatureRequests();
    };
  }, [tag]);

  const openDocGenDrawer = () => {
    setOpenDocGen(true);
    window.history.pushState(null, "");
  };

  const openImageGenDrawer = () => {
    setOpenImageGen(true);
    window.history.pushState(null, "");
  };

  const openInfoGenDrawer = () => {
    setOpenInfoGen(true);
    window.history.pushState(null, "");
  };

  const openUploadGenDrawer = () => {
    setOpenUploadGen(true);
    window.history.pushState(null, "");
  };

  const openConfigurationGenDrawer = () => {
    setOpenConfigurationGen(true);
    window.history.pushState(null, "");
  };

  const handleOpenCertificationSuccessful = () => {
    setOpenCertificationSuccessful(true);
    window.history.pushState(null, "");
  };

  const handleOpenCertificationError = () => {
    setOpenCertificationError(true);
    window.history.pushState(null, "");
  };

  const handleTagNotExistDrawer = () => {
    setTagNotExist(true);
    window.history.pushState(null, "");
  };

  const handleTagConfDrawer = () => {
    setTagConf(true);
    window.history.pushState(null, "");
  };

  const handleTagNotExist = () => {
    setTagNotExist(false);
    navigate("/");
  };

  const handleCloseTagConf = () => {
    setTagConf(false);
    navigate("/");
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [value1, setValue1] = useState(0);

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
  };

  const handleCertify = (request) => {
    setRequest(request);

    if (request.type === "info") {
      openInfoGenDrawer();
    } else if (request.type === "img") {
      openImageGenDrawer();
    } else if (request.type === "doc") {
      openDocGenDrawer();
    }
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <>
      {isMobile ? (
        <Drawer
          anchor="bottom"
          open={tagNotExist}
          sx={{ "& .MuiDrawer-paper": { width: "100%" } }}
          PaperProps={{
            sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
          }}
        >
          <Box sx={{ p: "6%" }}>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <SearchOffOutlinedIcon fontSize="large" sx={{ color: "red" }} />
            </Box>
            <Typography variant="h5" color="red" align="center" gutterBottom>
              Not Found
            </Typography>
            <Typography variant="body1" color="red" align="center" gutterBottom>
              Databox: <b>{tag}</b> doesn't exist.
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}
            >
              <Button variant="contained" onClick={handleTagNotExist}>
                Close
              </Button>
            </Box>
          </Box>
        </Drawer>
      ) : (
        <Snackbar
          open={tagNotExist}
          autoHideDuration={3000}
          onClose={handleTagNotExist}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{ mt: { xs: "20%", md: "5%" } }}
        >
          <Alert
            onClose={handleTagNotExist}
            severity="error"
            sx={{ width: "100%" }}
          >
            Databox: <b>{tag}</b> doesn't exist.
          </Alert>
        </Snackbar>
      )}

      {currentTag && currentTag.name ? (
        <DataBoxFound
          tag={currentTag}
          open={tagConf}
          setOpen={setTagConf}
          handleClose={handleCloseTagConf}
        />
      ) : (
        <Drawer
          anchor={isMobile ? "bottom" : "right"}
          open={tagConf}
          onClose={handleCloseTagConf}
          sx={{ "& .MuiDrawer-paper": { width: { xs: "100%", sm: "30%" } } }}
          PaperProps={{
            sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
          }}
        >
          {!isMobile && <DrawerHeader />}
          <TagConfigure
            tag={tag}
            handleCloseTagConf={handleCloseTagConf}
            setTagConf={setTagConf}
          />
        </Drawer>
      )}

      {tagExist && (
        <>
          <Grid item container xs={12} spacing={5}>
            {(isTagGroupMember || isTagGroupMemberReadOnly) && seentag && (
              <Grid item xs={12}>
                <TagToolbar tag={seentag} />
              </Grid>
            )}

            <Grid item xs={12}>
              <Divider sx={{ borderBottomWidth: 1, bgcolor: "black" }} />
            </Grid>

            {tag === "E4qSNCkLyz" && (
              <Grid item container xs={12}>
                <Grid item xs={12} sm={6}>
                  <CompletenessCheck tag={tag} recordsCount={recordsCount} />
                </Grid>
              </Grid>
            )}

            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" fontWeight="bold">
                  {conservSostL1 ? t("documents") : t("certifications")}
                </Typography>
              </Grid>

              {isTagGroupMember && canSign && seedflow && (
                <Grid item container xs={12} justifyContent="flex-end">
                  <Grid item xs={12} sm={2}>
                    <CertificationMenu
                      openDocGenDrawer={openDocGenDrawer}
                      openImageGenDrawer={openImageGenDrawer}
                      openInfoGenDrawer={openInfoGenDrawer}
                      openConfigurationGenDrawer={openConfigurationGenDrawer}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid item container xs={12} spacing={5}>
                {types.includes("sensor_data_aggregated") && (
                  <Grid item xs={12}>
                    <SensorDataAggregatedViewer
                      tag={tag}
                      isTagGroupMember={isTagGroupMember}
                    />
                  </Grid>
                )}

                {(types.includes("cfg") || types.includes("mps")) && (
                  <Grid item xs={12}>
                    <Grid item container justifyContent="flex-end">
                      <Tabs
                        value={value1}
                        onChange={handleChange1}
                        textColor="primary"
                        indicatorColor="primary"
                      >
                        {types
                          .filter((type) => ["cfg", "mps"].includes(type))
                          .sort((a, b) => a.localeCompare(b))
                          .map((type, index) => {
                            let icon;
                            let label;

                            if (type === "cfg") {
                              icon = <ManageHistoryIcon fontSize="large" />;
                              label = "Configurations";
                            } else if (type === "mps") {
                              icon = <LegendToggleIcon fontSize="large" />;
                              label = "IOT Stream";
                            }

                            return (
                              <Tab key={index} icon={icon} label={label} />
                            );
                          })}
                      </Tabs>
                    </Grid>
                    <Grid item>
                      {types
                        .filter((type) => ["cfg", "mps"].includes(type))
                        .sort((a, b) => a.localeCompare(b))
                        .map((type, index) => (
                          <TabPanel key={index} value={value1} index={index}>
                            {type === "cfg" && (
                              <ConfigViewer
                                tag={tag}
                                isTagGroupMember={isTagGroupMember}
                              />
                            )}
                            {type === "mps" && (
                              <MpsViewer
                                tag={tag}
                                isTagGroupMember={isTagGroupMember}
                              />
                            )}
                          </TabPanel>
                        ))}
                    </Grid>
                  </Grid>
                )}

                {(types.includes("doc") ||
                  types.includes("img") ||
                  types.includes("info") ||
                  types.includes("like_sirti") ||
                  types.includes("source_telemetrics")) && (
                  <Grid item xs={12}>
                    <Grid item container justifyContent="flex-end">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="primary"
                        indicatorColor="primary"
                      >
                        {(types.includes("doc") ||
                          types.includes("img") ||
                          types.includes("info") ||
                          types.includes("like_sirti") ||
                          types.includes("source_telemetrics")) && (
                          <Tab
                            label={
                              <Typography color="primary">
                                {t("history")}
                              </Typography>
                            }
                          />
                        )}
                        {types
                          .filter((type) =>
                            [
                              "doc",
                              "img",
                              "info",
                              "like_sirti",
                              "souce_telemetrics",
                            ].includes(type)
                          )
                          .sort((a, b) => {
                            const order = [
                              "doc",
                              "img",
                              "info",
                              "like_sirti",
                              "source_telemetrics",
                            ];
                            return order.indexOf(a) - order.indexOf(b);
                          })
                          .map((type, index) => {
                            let icon;
                            let label;

                            if (type === "info") {
                              label = t("notes");
                            } else if (type === "doc") {
                              label = t("documents");
                            } else if (type === "img") {
                              label = t("images");
                            } else if (type === "like_sirti") {
                              label = "like_sirti";
                            } else if (type === "source_telemetrics") {
                              label = "source_telemetrics";
                            }

                            return (
                              <Tab
                                key={index}
                                icon={icon}
                                label={
                                  <Typography color="primary">
                                    {label}
                                  </Typography>
                                }
                              />
                            );
                          })}
                      </Tabs>
                    </Grid>
                    <Grid item>
                      <TabPanel value={value} index={0}>
                        <AggregatedViewer
                          tag={tag}
                          isTagGroupMember={isTagGroupMember}
                        />
                      </TabPanel>
                      {types
                        .filter((type) =>
                          [
                            "doc",
                            "img",
                            "info",
                            "like_sirti",
                            "source_telemetrics",
                          ].includes(type)
                        )
                        .sort((a, b) => {
                          const order = [
                            "doc",
                            "img",
                            "info",
                            "like_sirti",
                            "source_telemetrics",
                          ];
                          return order.indexOf(a) - order.indexOf(b);
                        })
                        .map((type, index) => (
                          <TabPanel
                            key={index + 1}
                            value={value}
                            index={index + 1}
                          >
                            {type === "doc" && (
                              <DocViewer
                                tag={tag}
                                isTagGroupMember={isTagGroupMember}
                              />
                            )}
                            {type === "img" && (
                              <ImageViewer
                                tag={tag}
                                isTagGroupMember={isTagGroupMember}
                              />
                            )}
                            {type === "info" && (
                              <InfoViewer
                                tag={tag}
                                isTagGroupMember={isTagGroupMember}
                              />
                            )}
                            {type === "like_sirti" && (
                              <LikeSirtiViewer
                                tag={tag}
                                isTagGroupMember={isTagGroupMember}
                              />
                            )}
                          </TabPanel>
                        ))}
                    </Grid>
                  </Grid>
                )}

                {requests && !conservSostL1 && (
                  <Grid item xs={12}>
                    <RequestsTable
                      requests={requests}
                      handleCertify={handleCertify}
                    />
                  </Grid>
                )}

                {conservSostL1 &&
                  (isTagGroupMember || isTagGroupMemberReadOnly) && (
                    <Grid item xs={12}>
                      <CDNFileViewer
                        tag={tag}
                        isTagGroupMember={
                          isTagGroupMember || isTagGroupMemberReadOnly
                        }
                        openUploadGenDrawer={openUploadGenDrawer}
                      />
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>

          <DocGenerator
            tag={tag}
            types={types}
            setTypes={setTypes}
            open={openDocGen}
            setOpen={setOpenDocGen}
            handleOpenCertificationSuccessful={
              handleOpenCertificationSuccessful
            }
            handleOpenCertificationError={handleOpenCertificationError}
            request={request}
            setRequest={setRequest}
          />

          <ImageGenerator
            tag={tag}
            types={types}
            setTypes={setTypes}
            open={openImageGen}
            setOpen={setOpenImageGen}
            handleOpenCertificationSuccessful={
              handleOpenCertificationSuccessful
            }
            handleOpenCertificationError={handleOpenCertificationError}
            request={request}
            setRequest={setRequest}
          />

          <InfoGenerator
            tag={tag}
            types={types}
            setTypes={setTypes}
            open={openInfoGen}
            setOpen={setOpenInfoGen}
            handleOpenCertificationSuccessful={
              handleOpenCertificationSuccessful
            }
            handleOpenCertificationError={handleOpenCertificationError}
            request={request}
            setRequest={setRequest}
          />

          {currentTag &&
            currentTag.targets &&
            currentTag.targets.length !== 0 && (
              <ConfigGenerator
                tag={tag}
                targets={currentTag.targets}
                types={types}
                setTypes={setTypes}
                open={openConfigurationGen}
                setOpen={setOpenConfigurationGen}
                handleOpenCertificationSuccessful={
                  handleOpenCertificationSuccessful
                }
                handleOpenCertificationError={handleOpenCertificationError}
                request={request}
                setRequest={setRequest}
              />
            )}

          {conservSostL1 && (
            <CDNFileGenerator
              tag={tag}
              types={types}
              setTypes={setTypes}
              open={openUploadGen}
              setOpen={setOpenUploadGen}
              handleOpenCertificationSuccessful={
                handleOpenCertificationSuccessful
              }
              handleOpenCertificationError={handleOpenCertificationError}
              request={request}
              setRequest={setRequest}
            />
          )}

          <CertificationSuccessful
            open={openCertificationSuccessful}
            setOpen={setOpenCertificationSuccessful}
          />
          <CertificationError
            open={openCertificationError}
            setOpen={setOpenCertificationError}
          />
        </>
      )}
    </>
  );
};

export default Tag;
