// Firebase
import { db } from 'config/firebase';
import { collection, doc, updateDoc } from 'firebase/firestore';

// Functions
import unixTimestampInSeconds from 'utils/unixTimestampInSeconds';

// A ---------------------------------------------------------------------- M

const completeCertificationRequest = async (tdr, id) => {
    try {
        const tagRef = doc(db, 'tagsdata', tdr);
        const collectionRef = collection(tagRef, 'signature_requests');
        const reqRef = doc(collectionRef, id);

        await updateDoc(reqRef, {
            completed: true,
            completed_on: unixTimestampInSeconds(),
        });
    } catch (e) {
        console.error('Error in completeCertificationRequest:', e.message);
    }
};

export default completeCertificationRequest;