// React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// Functions
import addNewUser from "user/addNewUser";

// A ---------------------------------------------------------------------- M

const NewUser = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [email, setEmail] = useState("");
  const [canCreateDatabox, setCanCreateDatabox] = useState(false);
  const [canCreateGroup, setCanCreateGroup] = useState(false);
  const [canSign, setCanSign] = useState(false);
  const [isReseller, setIsReseller] = useState(false);
  const [canAddUser, setCanAddUser] = useState(false);
  const [conservSostL1, setConservSostL1] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addNewUser(
      email,
      canCreateDatabox,
      canCreateGroup,
      canSign,
      isReseller,
      canAddUser,
      conservSostL1
    );
    navigate("/");
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          mt: isMobile ? 15 : 30,
          p: isMobile ? 2 : 3,
          boxShadow: 3,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant={isMobile ? "h6" : "h5"} mb="5%">
          {t("add_new_user")}
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="Email Address"
                label={t("email")}
                name="Email Address"
                variant="outlined"
                required
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={canCreateDatabox}
                    onChange={(e) => setCanCreateDatabox(e.target.checked)}
                  />
                }
                label="canCreateDatabox"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={canCreateGroup}
                    onChange={(e) => setCanCreateGroup(e.target.checked)}
                  />
                }
                label="canCreateGroup"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={canSign}
                    onChange={(e) => setCanSign(e.target.checked)}
                  />
                }
                label="canSign"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={isReseller}
                    onChange={(e) => setIsReseller(e.target.checked)}
                  />
                }
                label="isReseller"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={canAddUser}
                    onChange={(e) => setCanAddUser(e.target.checked)}
                  />
                }
                label="canAddUser"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={conservSostL1}
                    onChange={(e) => setConservSostL1(e.target.checked)}
                  />
                }
                label="conservSostL1"
              />
            </Grid>
            <Grid item container xs={12}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={!email}
              >
                {t("add")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default NewUser;
