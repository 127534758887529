// Firebase
import { db } from 'config/firebase';
import { collection, query, where, getDocs, doc } from 'firebase/firestore';

// Functions
import fetchSignature from 'record/fetchSignature';

// A ---------------------------------------------------------------------- M

/**
 * Fetches CDN File Records based on the provided tagID and permission.
 * @param {string} tagID - The ID of the databox.
 * @param {boolean} isTagGroupMember - Permission flag for the tag group member.
 * @param {Object} filterCriteria - Filter to apply on query.
 * @param {boolean} isRecent - Flag to fetch recent records only.
 * @returns {Promise<Array<Object> | null>} - Array of signature objects or null if error occurs.
 */
const fetchCDNFileRecords = async (tagID, isTagGroupMember, filterCriteria, isRecent = false) => {
    try {
        if (!isTagGroupMember) {
            console.error('Error in fetchCDNFileRecords: missing permissions');
            return null;
        }

        const tagRef = doc(db, 'tagsdata', tagID);
        const signaturesRef = collection(tagRef, 'signatures');

        let q = query(signaturesRef, where('type', '==', 'CDNFile'));

        if (isRecent) {
            // 30 days ago
            const referenceTime = ((Date.now() / 1000) - (30 * 24 * 60 * 60)).toString();
            q = query(q, where('timestamp', '>', referenceTime));
        }

        // Apply only dataDocumento related filter criteria to the Firestore query
        if (filterCriteria.dataDocumentoStart) {
            q = query(q, where('dataDocumento', '>=', filterCriteria.dataDocumentoStart));
        }
        if (filterCriteria.dataDocumentoEnd) {
            q = query(q, where('dataDocumento', '<=', filterCriteria.dataDocumentoEnd));
        }

        const snapshot = await getDocs(q);

        // Filter documents locally based on the remaining filter criteria
        const filteredDocs = snapshot.docs.filter((doc) => {
            const data = doc.data();
            let matches = true;

            Object.keys(filterCriteria).forEach((key) => {
                if (filterCriteria[key] && !['dataDocumentoStart', 'dataDocumentoEnd'].includes(key)) {
                    if (key === 'dataRegistrazioneStart' && data.dataRegistrazione < filterCriteria[key]) {
                        matches = false;
                    }
                    if (key === 'dataRegistrazioneEnd' && data.dataRegistrazione > filterCriteria[key]) {
                        matches = false;
                    }
                    if (key !== 'dataRegistrazioneStart' && key !== 'dataRegistrazioneEnd' && data[key] !== filterCriteria[key]) {
                        matches = false;
                    }
                }
            });

            return matches;
        });

        const signaturePromises = filteredDocs.map(async (sig) => {
            const { txid, public: isPublic = false } = sig.data();
            const signature = await fetchSignature(txid, isPublic);
            return signature;
        });

        const signatures = await Promise.all(signaturePromises);
        return signatures.filter((obj) => obj !== undefined);
    } catch (e) {
        console.error(`Error in fetchCDNFileRecords: ${e.message}`, e);
        return null;
    }
};

export default fetchCDNFileRecords;