import { db } from 'config/firebase';
import { doc, getDoc } from 'firebase/firestore';

// A ---------------------------------------------------------------------- M

/**
 * Retrieves the public key URL for a given user UUID from Firestore.
 * @param {string} uuid - The UUID of the user.
 * @returns {Promise<string>} A promise that resolves to the public key URL or an empty string.
 */
const getPubKeyUrl = async (uuid) => {
    try {
        const userRef = doc(db, 'usercontacts', uuid);
        const userDoc = await getDoc(userRef);
        return userDoc.exists() ? userDoc.data().pubkeyurl ?? '' : '';
    } catch (e) {
        console.error('Error in getPubKeyUrl: ', e.message);
        return '';
    }
};

export default getPubKeyUrl;