// React
import React from "react";
import { Link } from "react-router-dom";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";

// A ---------------------------------------------------------------------- M

// Install PWA
export const InstallBanner = (props) => {
  const { t } = useTranslation();

  return (
    <Snackbar
      open={props.showInstallBanner}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      message={t("install_pwa_description")}
      action={[
        <Button key="action1" onClick={props.handleCloseInstallBanner}>
          {t("dismiss")}
        </Button>,
        <Button key="action2" onClick={props.handleClick}>
          {t("install")}
        </Button>,
      ]}
      sx={{ mt: { xs: "55px", md: "5%" } }}
    />
  );
};

// Open with PWA
export const OpenWithBanner = (props) => {
  const { t } = useTranslation();

  return (
    <Snackbar
      open={props.showOpenBanner}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      message={t("open_with_app")}
      action={[
        <Button key="action1" onClick={props.handleCloseOpenBanner}>
          {t("dismissi")}
        </Button>,
        <Link key="action2" target="_blank" to="/signin">
          <Button>{t("open")}</Button>
        </Link>,
      ]}
      sx={{ mt: { xs: "55px", md: "5%" } }}
    />
  );
};

// Update PWA
export const UpdateBanner = (props) => {
  const { t } = useTranslation();

  return (
    <Snackbar
      open={props.showUpdateBanner}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      message={t("new_pwa_version_available")}
      action={[
        <Button key="action1" onClick={props.reloadPage}>
          {t("refresh")}
        </Button>,
      ]}
      sx={{ mt: { xs: "55px", md: "5%" } }}
    />
  );
};
