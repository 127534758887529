import { db } from 'config/firebase';
import { doc, getDoc } from 'firebase/firestore';

/**
 * Retrieves the server's encrypted seed and nonce for a given user.
 * 
 * This function queries the 'userseeds' collection in Firestore using the provided UUID
 * to fetch the user-specific encrypted seed and nonce.
 * 
 * @param {string} uuid - The unique identifier for the user.
 * @returns {Promise<{server_encrypted_seed: string, nonce: string} | null>} A promise that resolves to an object containing the encrypted seed and nonce, or null if an error occurs or data is not found.
 */
const getServerEncryptedSeed = async (uuid) => {
    try {
        const seedRef = doc(db, "userseeds", uuid);
        const seedDoc = await getDoc(seedRef);

        if (!seedDoc.exists()) {
            console.error('No seed data found for UUID:', uuid);
            return null;
        }

        const seedData = seedDoc.data();
        return {
            server_encrypted_seed: seedData.encrypted_seed,
            nonce: seedData.nonce
        };
    } catch (e) {
        console.error('Error in getting server encrypted seed: ', e.message);
        return null;
    }
};

export default getServerEncryptedSeed;