// React
import React, { useEffect, useState } from "react";

// Context
import { UserAuth } from "context/AuthContext";

// i18n
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// Material UI Icons
import AddIcon from "@mui/icons-material/Add";

// Components
import { Puller } from "ui-components/Puller";
import { LoadingComponent } from "ui-components/LoadingComponent";

// Functions
import createGroup from "group/createGroup";

// A ---------------------------------------------------------------------- M

const GroupGenerator = ({ open, setOpen, handleSuccessful }) => {
  const { user } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [groupname, setGroupname] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  const handleCreateGroup = async () => {
    setIsLoading(true);
    try {
      await createGroup(user.uid, groupname);
    } catch (error) {
      console.error("Error in handleCreateGroup:", error.message);
    }
    setIsLoading(false);
    handleReset();
    handleSuccessful();
  };

  const handleReset = () => {
    setGroupname("");
    setOpen(false);
  };

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "45%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          mt="5%"
          mb="10%"
        >
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {t("new_group")}
            </Typography>
          </Grid>
        </Grid>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <TextField
              fullWidth
              margin="dense"
              id="groupName"
              name="groupName"
              label={t("name")}
              required
              variant="outlined"
              value={groupname}
              onChange={(e) => setGroupname(e.target.value)}
            />
            <Grid item mt="5%">
              <Button
                fullWidth
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleCreateGroup}
                disabled={!groupname}
              >
                {t("create")}
              </Button>
            </Grid>
          </>
        )}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={handleReset} maxWidth="md" fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("new_group")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleReset} edge="end" sx={{ color: "red" }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <TextField
              fullWidth
              margin="dense"
              name="groupName"
              id="groupName"
              label={t("name")}
              required
              variant="outlined"
              value={groupname}
              onChange={(e) => setGroupname(e.target.value)}
            />
            <Grid item mt="3%">
              <Button
                fullWidth
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleCreateGroup}
                disabled={!groupname}
              >
                {t("create")}
              </Button>
            </Grid>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default GroupGenerator;
