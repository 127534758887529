import { db } from 'config/firebase';
import { doc, collection, getDoc } from 'firebase/firestore';

// A ---------------------------------------------------------------------- M

const fetchUserSeenTagName = async (uuid, tdr) => {
    try {
        const userRef = doc(db, 'userdata', uuid);
        const seentagsRef = collection(userRef, 'seentags');
        const seentagRef = doc(seentagsRef, tdr);
        const seentag = await getDoc(seentagRef);
        return seentag.exists() ? seentag.data().nickname ?? '' : '';
    } catch (e) {
        console.error("Error in fetchUserSeenTagName:", e.message);
        return '';
    }
};

export default fetchUserSeenTagName;