// Firebase
import { db } from 'config/firebase';
import { doc, getDoc } from 'firebase/firestore';

// A ---------------------------------------------------------------------- M

const getTagOwner = async (tagID) => {
    try {
        const tagRef = doc(db, 'tagsdata', tagID);
        const tagSnapshot = await getDoc(tagRef);

        if (tagSnapshot.exists()) {
            const tagData = tagSnapshot.data();

            if (tagData.owner_uuid) {
                return tagData.owner_uuid
            } else {
                return 'N/A';
            }
        } else {
            return 'N/A';
        }
    } catch (e) {
        console.log("Error in getTagOwner:", e.message);
        return 'N/A';
    }
};

export default getTagOwner;