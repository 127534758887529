// Firebase
import { db } from "config/firebase";
import { doc, collection, setDoc } from "firebase/firestore";

// Functions
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

/**
 * Creates a new document in both the 'keysecrets' and 'keydata' collections in Firestore.
 * Both documents will have the same document ID but will contain different data.
 * In 'keysecrets', the document will contain the data key and owner's UUID.
 * In 'keydata', the document will store the name, owner's UUID and the creation timestamp.
 *
 * @param {string} datakey - The data key to store in the 'keysecrets' collection.
 * @param {string} name - The name to store in the 'keydata' collection.
 * @param {string} uuid - The UUID of the owner to associate with these documents.
 * @returns {Promise<string>} The ID of the newly created document in both collections.
 */
const createDataKey = async (datakey, name, uuid) => {
  try {
    const keyID = doc(collection(db, "keysecrets")).id;
    const keySecretsDocRef = doc(db, "keysecrets", keyID);
    const keyDataDocRef = doc(db, "keydata", keyID);
    const createdOn = unixTimestampInSeconds();

    await setDoc(keySecretsDocRef, {
      datakey: datakey,
      owner_uuid: uuid,
    });

    await setDoc(keyDataDocRef, {
      name: name,
      owner_uuid: uuid,
      created_on: createdOn,
    });

    console.log("Create datakey with ID:", keyID);
    return keyID;
  } catch (e) {
    console.error("Error in createDataKey:", e.message);
    return null;
  }
};

export default createDataKey;
