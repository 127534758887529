// Firebase
import { db } from "config/firebase";
import { collection, deleteDoc, doc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

/**
 * Deletes a forward route from the Firestore database.
 * Deletes the document in the 'forward_routes' subcollection of the specified databox document.
 *
 * @param {string} routeiD - The unique identifier of the forward route document to be deleted.
 * @param {string} tagID - The unique identifier of the databox document in Firestore.
 */
const removeForwardRoute = async (routeID, tagID) => {
  try {
    const tagRef = doc(db, "tagsdata", tagID);
    const forwardRoutesRef = collection(tagRef, "forward_routes");
    const forwardRouteDocRef = doc(forwardRoutesRef, routeID);

    await deleteDoc(forwardRouteDocRef);
    console.log(
      `Document with route ID ${routeID} from tag ID ${tagID} has been successfully deleted.`
    );
  } catch (e) {
    console.error("Error in deleteForwardRoute:", e.message);
  }
};

export default removeForwardRoute;
