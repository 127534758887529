// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

// Icons
import FirstPageOutlinedIcon from "@mui/icons-material/FirstPageOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import LastPageOutlinedIcon from "@mui/icons-material/LastPageOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AddIcon from "@mui/icons-material/Add";

// Functions
import convertTimestamp from "utils/convertTimestamp";
import removeAuthorizedKey from "tag/removeAuthorizedKey";

// A ---------------------------------------------------------------------- M

const EnabledDataKeysTableHead = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const headCells = [
    { id: "name", label: t("name"), mobileLabel: t("name") },
    {
      id: "timestamp",
      label: t("addition_datetime"),
      mobileLabel: t("addition_datetime"),
    },
    { id: "datakey", label: t("datakey"), mobileLabel: t("datakey") },
    { id: "actions", label: t("actions"), mobileLabel: t("actions") },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          (headCell) =>
            (!isMobile ||
              headCell.id === "name" ||
              headCell.id === "timestamp" ||
              headCell.id === "actions") && (
              <TableCell
                key={headCell.id}
                align={headCell.id !== "actions" ? "left" : "right"}
              >
                {isMobile ? headCell.mobileLabel : headCell.label}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
};

const EnabledDataKeysTableToolbar = ({ openAddDataKeyDialog }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6}>
        <Typography variant="h6">{t("authorized_datakeys")}</Typography>
      </Grid>
      <Grid item container xs={12} sm={12} md={6} justifyContent="flex-end">
        <Grid item xs={12} sm={12} md={4}>
          <Button
            variant="contained"
            fullWidth
            onClick={openAddDataKeyDialog}
            startIcon={<AddIcon />}
          >
            {t("add_datakey")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const DataRow = ({ datakey, tag }) => {
  const [showDataKey, setShowDataKey] = useState(false);
  const { t } = useTranslation();

  const toggleDataKeyVisibility = () => {
    setShowDataKey(!showDataKey);
  };

  return (
    <TableRow sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}>
      <TableCell align="left">{datakey.name ? datakey.name : "N/A"}</TableCell>
      <TableCell align="left">
        {datakey.added_on ? convertTimestamp(datakey.added_on) : "N/A"}
      </TableCell>
      <TableCell align="left" sx={{ minWidth: "180px", maxWidth: "180px" }}>
        <span style={{ marginRight: "10px" }}>
          {showDataKey ? datakey.datakey : "••••••"}
        </span>
        <Tooltip title={showDataKey ? t("hide") : t("show")}>
          <IconButton onClick={toggleDataKeyVisibility} size="small">
            {showDataKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell align="right" size="small">
        <Button
          sx={{ color: "red" }}
          size="small"
          onClick={() => removeAuthorizedKey(tag.id, datakey.id)}
        >
          {t("remove")}
        </Button>
      </TableCell>
    </TableRow>
  );
};

const TablePaginationActions = (props) => {
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <LastPageOutlinedIcon />
        ) : (
          <FirstPageOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRightOutlinedIcon />
        ) : (
          <KeyboardArrowLeftOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeftOutlinedIcon />
        ) : (
          <KeyboardArrowRightOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageOutlinedIcon />
        ) : (
          <LastPageOutlinedIcon />
        )}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const EnabledDataKeysTable = ({ tag, dataKeys, openAddDataKeyDialog }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataKeys.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFirstPageButtonClick = () => {
    setPage(0);
  };

  const handleBackButtonClick = () => {
    setPage(page - 1);
  };

  const handleNextButtonClick = () => {
    setPage(page + 1);
  };

  const handleLastPageButtonClick = () => {
    setPage(Math.max(0, Math.ceil(dataKeys.length / rowsPerPage) - 1));
  };

  return (
    <Box>
      <EnabledDataKeysTableToolbar
        openAddDataKeyDialog={openAddDataKeyDialog}
      />
      <TableContainer>
        <Table>
          <EnabledDataKeysTableHead />
          <TableBody>
            {(rowsPerPage > 0
              ? dataKeys.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : dataKeys
            ).map((datakey) => {
              return <DataRow key={datakey.id} datakey={datakey} tag={tag} />;
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, { label: "All", value: -1 }]}
                  colSpan={4}
                  count={dataKeys.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {isMobile && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
            >
              <FirstPageOutlinedIcon />
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(dataKeys.length / rowsPerPage) - 1}
            >
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(dataKeys.length / rowsPerPage) - 1}
            >
              <LastPageOutlinedIcon />
            </IconButton>
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

export default EnabledDataKeysTable;
