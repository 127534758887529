import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "config/firebase";

const getUUIDFromEmail = async (new_member_email) => {
    // this function supposes that two different users (different uuid) must not have the same email
    try {
        const q = query(collection(db, 'usercontacts'), where('email', '==', new_member_email));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            return null;
        } else {
            return querySnapshot.docs[0].id;
        }
    } catch (e) {
        console.error("Get UUID from email failed: ", e.message);
        return null;
    }
};

export default getUUIDFromEmail;