// Firebase
import { db } from "config/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const removeFollower = async (uid, tag) => {
    try {
        const tagDocRef = doc(db, "tagsdata", tag);
        const tagDocSnapshot = await getDoc(tagDocRef);

        if (tagDocSnapshot.exists()) {
            const tagData = tagDocSnapshot.data();

            if (tagData.followers) {
                const updatedFollowersArray = tagData.followers.filter(
                    (followerUid) => followerUid !== uid
                );

                await updateDoc(tagDocRef, { followers: updatedFollowersArray });
            }
        }
    } catch (e) {
        console.error("Error in removeFollower:", e.message);
    }
};

export default removeFollower;