// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const checkIfUserIsFollowing = async (uid, tag) => {
    try {
        const tagDocRef = doc(db, "tagsdata", tag);
        const tagDocSnapshot = await getDoc(tagDocRef);

        if (tagDocSnapshot.exists()) {
            const tagData = tagDocSnapshot.data();

            if (tagData.followers) {
                const isFollowing = tagData.followers.includes(uid);

                if (isFollowing) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    } catch (e) {
        console.error("Error in checkIfUserIsFollowing:", e.message);
    }
};

export default checkIfUserIsFollowing;
