// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

/**
 * Retrieves contact information for a user, identified by their UUID. This includes the user's email,
 * public key, and company name.
 *
 * @param {string} uuid - The UUID of the user whose contact information is being retrieved.
 * @returns {Promise<{email: string, public_key: string, company: string}>} A promise that resolves to an object containing the user's email, public key, and company.
 * If the user does not exist or certain fields are missing, it returns default values ('IOT device' for email,
 * and empty strings for public_key and company). In case of an error, it logs the error and returns an object with empty strings for all fields.
 */
const getUserContacts = async (uuid) => {
  try {
    const userRef = doc(db, "usercontacts", uuid);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      return {
        email: userData.email || "",
        public_key: userData.public_key || "",
        company: userData.company || "",
      };
    } else {
      return {
        email: "",
        public_key: "",
        company: "",
      };
    }
  } catch (e) {
    console.error("Error in getUserContacts:", e.message);
    return {
      email: "",
      public_key: "",
      company: "",
    };
  }
};

export default getUserContacts;
