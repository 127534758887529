// Firebase
import { db } from 'config/firebase';
import { collection, query, getDocs, doc } from 'firebase/firestore';

// Functions
import fetchRequest from './fetchRequest';

// A ---------------------------------------------------------------------- M

const fetchRequests = async (tagID) => {
    try {
        const tagRef = doc(db, "tagsdata", tagID);
        const signaturesRef = collection(tagRef, "signature_requests");

        let q;

        q = query(signaturesRef);

        const snapshot = await getDocs(q);
        const requestPromises = snapshot.docs.map(async (req) => {
            const id = req.id;
            const request = await fetchRequest(id);
            return {
                ...request,
                completed: req.data().completed || false,
            };
        });

        const requests = await Promise.all(requestPromises);
        const requestsFiltered = requests.filter(obj => obj !== undefined);
        return requestsFiltered;
    } catch (e) {
        console.error('Error in fetchRequests:', e.message);
        return null;
    }
};

export default fetchRequests;