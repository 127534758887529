// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material UI
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

// Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TaskIcon from "@mui/icons-material/Task";
import DownloadIcon from "@mui/icons-material/Download";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import FolderZipIcon from "@mui/icons-material/FolderZip";

// Components
import CDNFileUploadReceipt from "ui-components/generators/CDNFileGenerator/CDNFileUploadReceipt";

// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// Functions
import downloadXMLFile from "storage/downloadXMLFile";
import downloadFile from "storage/downloadFile";
import convertTimestamp from "utils/convertTimestamp";
import getUserContacts from "user/getUserContacts";
import downloadAsZip from "storage/downloadAsZip";

// A ---------------------------------------------------------------------- M

const CDNPreservationProofDialog = ({ open, onClose, record }) => {
  const [uploadReceipt, setUploadReceipt] = useState(null);
  const [receiptDialogOpen, setReceiptDialogOpen] = useState(false);

  useEffect(() => {
    const fetchUploadReceipt = async () => {
      try {
        if (record && record.txid) {
          const docRef = doc(db, "uploadReceipts", record.txid);
          const docSnapshot = await getDoc(docRef);

          if (docSnapshot.exists()) {
            const receiptData = docSnapshot.data();
            console.log(receiptData);

            // Fetch creator contacts and email
            const creatorContacts = await getUserContacts(
              receiptData.creator_uuid
            );
            const creatorEmail = creatorContacts?.email || "N/A";

            // Update state with receipt data and email
            setUploadReceipt({ ...receiptData, creatorEmail });
          } else {
            console.log("No such document exists.");
          }
        }
      } catch (error) {
        console.error("Error fetching upload receipt: ", error);
      }
    };

    if (record) {
      fetchUploadReceipt();
    }
  }, [record]);

  const handleResetUploadReceipt = () => {
    setReceiptDialogOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <Grid container p="2%" spacing={3}>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              Prova Di Conservazione
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose} sx={{ color: "red" }} edge="end">
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>

        {/* Indice Di Conservazione Section */}
        <Grid item xs={12}>
          <Paper elevation={5} sx={{ p: "3%" }}>
            <Grid container>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={0.5}>
                  <CheckCircleIcon sx={{ color: "green" }} fontSize="large" />
                </Grid>
                <Grid item xs={8.5}>
                  <Typography>
                    L'indice di conservazione (IdC UniSincro) è stato{" "}
                    <b>firmato digitalmente</b> e <b>marcato temporalmente</b>{" "}
                    <br />
                    in data{" "}
                    <b>{convertTimestamp(record.data.timestamp) || "N/A"}</b>.
                  </Typography>
                </Grid>
                <Grid item container xs={3} spacing={1}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      startIcon={<DownloadIcon />}
                      onClick={() =>
                        downloadXMLFile(
                          `${record.uploadID}/${record.id}_IdC.xml`,
                          "IdC",
                          record.id
                        )
                      }
                    >
                      Download IdC
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      startIcon={<DownloadIcon />}
                      onClick={() =>
                        downloadXMLFile(
                          `${record.uploadID}/${record.id}_IdC_signed.xml.p7m`,
                          "IdC_signed",
                          record.id
                        )
                      }
                    >
                      Download Signed IdC
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Files Section */}
        <Grid item xs={12}>
          <Paper elevation={5} sx={{ p: "2%" }}>
            <Grid container spacing={3}>
              {/* File Section */}
              <Grid item xs={12}>
                <Paper elevation={4} sx={{ p: "1%" }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={0.5}>
                      <TaskIcon sx={{ color: "green" }} fontSize="large" />
                    </Grid>
                    <Grid item container xs={8.5} direction="column">
                      <Grid item>
                        <Typography>Nome File: {record.nomeFile}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography>Descrizione: File Dati</Typography>
                      </Grid>
                      <Grid item>
                        <Typography>Mime-Type: {record.formatoFile}</Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={3}
                      justifyContent="flex-end"
                      spacing={1}
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          fullWidth
                          startIcon={<DownloadIcon />}
                          onClick={() =>
                            downloadFile(
                              record.data.attachment,
                              record.nomeFile
                            )
                          }
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {/* Gluer Section */}
              <Grid item xs={12}>
                <Paper elevation={4} sx={{ p: "1%" }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={0.5}>
                      <TaskIcon sx={{ color: "green" }} fontSize="large" />
                    </Grid>
                    <Grid item xs={8.5} container direction="column">
                      <Grid item>
                        <Typography>Nome File: gluer.xml</Typography>
                      </Grid>
                      <Grid item>
                        <Typography>Descrizione: File Parametri</Typography>
                      </Grid>
                      <Grid item>
                        <Typography>Mime-Type: application/xml</Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={3}
                      justifyContent="flex-end"
                      spacing={1}
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          fullWidth
                          startIcon={<DownloadIcon />}
                          onClick={() =>
                            downloadXMLFile(
                              `${record.uploadID}/${record.id}_gluer.xml`,
                              "gluer",
                              record.id
                            )
                          }
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {/* Metadata Section */}
              <Grid item xs={12}>
                <Paper elevation={4} sx={{ p: "1%" }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={0.5}>
                      <TaskIcon sx={{ color: "green" }} fontSize="large" />
                    </Grid>
                    <Grid item container xs={8.5} direction="column">
                      <Grid item>
                        <Typography>Nome File: metadata.xml</Typography>
                      </Grid>
                      <Grid item>
                        <Typography>Descrizione: File Metadati</Typography>
                      </Grid>
                      <Grid item>
                        <Typography>Mime-Type: application/xml</Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={3}
                      justifyContent="flex-end"
                      spacing={1}
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          fullWidth
                          startIcon={<DownloadIcon />}
                          onClick={() =>
                            downloadXMLFile(
                              `${record.uploadID}/${record.id}_metadata.xml`,
                              "metadata",
                              record.id
                            )
                          }
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Upload Receipt Button */}
        <Grid item xs={12} sm={6}>
          <Button
            startIcon={<FileOpenIcon />}
            variant="contained"
            fullWidth
            onClick={() => setReceiptDialogOpen(true)}
          >
            Open Upload Receipt
          </Button>
        </Grid>

        {/* Download As ZIP Button */}
        <Grid item xs={12} sm={6}>
          <Button
            startIcon={<FolderZipIcon />}
            variant="contained"
            fullWidth
            onClick={() => downloadAsZip(record.uploadID)}
          >
            Download As ZIP
          </Button>
        </Grid>
      </Grid>

      {/* Upload Receipt Dialog */}
      {receiptDialogOpen && uploadReceipt && (
        <CDNFileUploadReceipt
          receiptDialogOpen={receiptDialogOpen}
          handleResetUploadReceipt={handleResetUploadReceipt}
          uploadReceipt={uploadReceipt}
        />
      )}
    </Dialog>
  );
};

CDNPreservationProofDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
};

export default CDNPreservationProofDialog;
