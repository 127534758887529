import { db } from "config/firebase";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

/**
 * Adds enabled public key for a user.
 * @param {string} uuid - The user's UID.
 * @param {Object} data - The data to be added, must contain a publicKey.
 */
const activatePublicKey = async (uuid, data) => {
    if (!data.publicKey) console.error("publicKey is required in the data object");

    try {
        // Reference to the user's document
        const userRef = doc(db, 'enabled_records', uuid);

        // Retrieve the user document
        const userDoc = await getDoc(userRef);

        // If the user document does not exist, create a new one
        if (!userDoc.exists()) {
            await setDoc(userRef, {});
        }

        // Reference to the 'enabled_public_keys' sub-collection under the user document
        const enabledPublicKeysRef = collection(userRef, 'enabled_public_keys');

        const keyData = {
            ...data,
            added_on: unixTimestampInSeconds()
        };

        // Add a new document in 'enabled_public_keys' collection with the provided data.publicKey
        await setDoc(doc(enabledPublicKeysRef, data.publicKey), keyData);

        return {
            success: true,
            publicKey: data.publicKey,
        };
    } catch (e) {
        console.error("Error in activatePublicKey:", e.message);

        return {
            success: false,
            message: `Error in activatePublicKey: ${e.message}`,
        };
    }
};

export default activatePublicKey;