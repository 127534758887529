import { collection, setDoc, doc } from "firebase/firestore";
import { db } from "config/firebase";
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

/**
 * Creates a new group in the Firestore database within the 'groupsdata' collection.
 * Additionally, it creates a record for the group creator in the 'users' subcollection
 * of the newly created group document.
 *
 * @param {string} uuid - The UUID of the group creator.
 * @param {string} name - The name of the group.
 * @returns {Promise<void>} A promise that resolves if the group creation is successful,
 * and rejects if an error occurs.
 */
const createGroup = async (uuid, name) => {
    try {
        const groupRef = doc(collection(db, 'groupsdata'));

        await setDoc(groupRef, {
            name: name,
            creator_uuid: uuid,
            members: 1,
            created_on: unixTimestampInSeconds(),
        });

        const usersRef = collection(groupRef, 'users');

        await setDoc(doc(usersRef, uuid), {
            uid: uuid,
            added_on: unixTimestampInSeconds(),
        });
    } catch (e) {
        console.error('Error in createGroup:', e.message);
    }
};

export default createGroup;