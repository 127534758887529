// React
import React, { useState, useEffect } from "react";

// Context
import { UserAuth } from "context/AuthContext";

// Components
import DocTable from "./DocTable";
import DataDialog from "ui-components/DataDialog";
import DatapointIntegrityInspector from "ui-components/DatapointIntegrityInspector";
import { LoadingDialog } from "ui-components/LoadingComponent";

// Firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// Functions
import fetchRecordsByType from "record/fetchRecordsByType";
import verifySignature from "verificator/verifySignature";

// A ---------------------------------------------------------------------- M

const DocViewer = ({ tag, isTagGroupMember }) => {
  const { user } = UserAuth();

  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState();
  const [verification, setVerification] = useState();
  const [openVerify, setOpenVerify] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);

  useEffect(() => {
    const handlePopState = () => {
      if (openVerify) {
        setOpenVerify(false);
      } else if (openView) {
        setOpenView(false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [openVerify, openView]);

  useEffect(() => {
    const tagsdataDocRef = doc(db, "tagsdata", tag);
    const signaturesCollectionRef = collection(tagsdataDocRef, "signatures");
    const currentTimestampInSecondsString = (Date.now() / 1000).toString();

    const newAdditionsQuery = query(
      signaturesCollectionRef,
      where("type", "==", "doc"),
      where("timestamp", ">", currentTimestampInSecondsString)
    );

    const newAdditionsUnsubscribe = onSnapshot(
      newAdditionsQuery,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            getRecords();
          }
        });
      }
    );

    const getRecords = async () => {
      const records = await fetchRecordsByType(tag, "doc", isTagGroupMember);

      records.sort((a, b) => {
        const timestampA = a.timestamp ?? 0;
        const timestampB = b.timestamp ?? 0;
        return timestampB - timestampA;
      });

      setRecords(records);
    };

    getRecords();

    return () => {
      newAdditionsUnsubscribe();
    };
  }, [tag, isTagGroupMember]);

  const handleOpenVerify = () => {
    setOpenVerify(true);
    window.history.pushState(null, "");
  };

  const handleOpenView = () => {
    setOpenView(true);
    window.history.pushState(null, "");
  };

  const checkVerification = async (record) => {
    try {
      setVerificationLoading(true);

      let verification;

      if (user) {
        verification = await verifySignature(user.uid, record, record.type);
      } else {
        verification = await verifySignature(false, record, record.type);
      }

      setRecord(record);
      setVerification(verification);
      handleOpenVerify();
    } catch (e) {
      console.error("Error in checkVerification:", e.message);
    } finally {
      setVerificationLoading(false);
    }
  };

  const handleView = (record) => {
    handleOpenView();
    setRecord(record);
  };

  return (
    <>
      {verificationLoading && <LoadingDialog open={verificationLoading} />}

      {records.length !== 0 && (
        <DocTable
          records={records}
          checkVerification={checkVerification}
          handleView={handleView}
        />
      )}

      {record && (
        <DataDialog
          data={record.data}
          open={openView}
          onClose={() => setOpenView(false)}
        />
      )}

      {record && verification && (
        <DatapointIntegrityInspector
          record={record}
          verification={verification}
          open={openVerify}
          setOpen={setOpenVerify}
        />
      )}
    </>
  );
};

export default DocViewer;
