// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Contexts
import { UserAuth } from "context/AuthContext";

// qrcode.react
import { QRCodeSVG } from "qrcode.react";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  SwipeableDrawer,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";

// Material UI Icons
import {
  CloseOutlined as CloseOutlinedIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  Add as AddIcon,
} from "@mui/icons-material";

// Components
import { Puller } from "ui-components/Puller";

// Functions
import { genRndStringPrintable } from "SafeTwin/crypto/cryptolibsodium";
import createDataBox from "tag/createDataBox";
import addSeenTag from "user/addSeenTag";

// A ---------------------------------------------------------------------- M

const DataBoxCreate = ({ open, setOpen, handleSuccessful }) => {
  const { user, conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [notes, setNotes] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [tagID, setTagID] = useState("");
  const [loading, setLoading] = useState(false);

  const steps = [
    { label: t("name"), description: t("new_databox_1") },
    { label: t("description"), description: t("new_databox_2") },
    { label: t("qr_code"), description: t("new_databox_3") },
  ];

  useEffect(() => {
    const handlePopstate = () => open && handleReset();
    window.addEventListener("popstate", handlePopstate);
    return () => window.removeEventListener("popstate", handlePopstate);
  }, [open]);

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);
  const generateNewString = () => setTagID(genRndStringPrintable(10));

  const generateNewTag = async () => {
    setLoading(true);
    await createDataBox(tagID, user.uid, name);
    await addSeenTag(tagID, user.uid, name, notes);
    setLoading(false);
    handleReset();
    handleSuccessful();
  };

  const handleReset = () => {
    setName("");
    setNotes("");
    setTagID("");
    setActiveStep(0);
    setLoading(false);
    setOpen(false);
  };

  const stepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <TextField
              margin="dense"
              label={t("name")}
              fullWidth
              required
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={handleNext}
                disabled={!name}
              >
                {t("continue")}
              </Button>
            </Box>
          </>
        );
      case 1:
        return (
          <>
            <TextField
              margin="dense"
              label={t("description")}
              fullWidth
              variant="outlined"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              multiline
              rows={2}
            />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                startIcon={<ArrowBackIcon />}
                onClick={handleBack}
              >
                {t("back")}
              </Button>
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={() => {
                  generateNewString();
                  handleNext();
                }}
              >
                {t("continue")}
              </Button>
            </Box>
          </>
        );
      case 2:
        return (
          <>
            <Box
              sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}
            >
              <QRCodeSVG
                value={`${process.env.REACT_APP_ENVIRONMENT_URL}/${tagID}`}
                style={{ height: 150, width: 150 }}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                startIcon={<ArrowBackIcon />}
                onClick={handleBack}
              >
                {t("back")}
              </Button>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={generateNewTag}
              >
                {t("create")}
              </Button>
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  const innerContent = (
    <Grid container>
      <Grid item xs={12}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>
                <b>{step.label}</b>
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                {stepContent(index)}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "70%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {conservSostL1 ? t("new_archive") : t("new_databox")}
            </Typography>
          </Grid>
        </Grid>
        {innerContent}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={handleReset} maxWidth="md" fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {conservSostL1 ? t("new_archive") : t("new_databox")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleReset} sx={{ color: "red" }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>{innerContent}</DialogContent>
    </Dialog>
  );
};

DataBoxCreate.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleSuccessful: PropTypes.func.isRequired,
};

export default DataBoxCreate;
