import { collection, doc, getDocs } from 'firebase/firestore';
import { db } from 'config/firebase';
import getUserContacts from 'user/getUserContacts';

// A ---------------------------------------------------------------------- M

const getGroupMembers = async (group_id) => {
    try {
        const membersSnapshot = await getDocs(collection(doc(db, 'groupsdata', group_id), 'users'));
        const memberPromises = membersSnapshot.docs.map(async (member) => {
            const { uid } = member.data();
            const contacts = await getUserContacts(uid);
            const email = contacts.email;

            return {
                uid,
                email
            };
        });

        const members = await Promise.all(memberPromises);
        return members;
    } catch (e) {
        console.error('Error in getGroupMembers:', e.message);
        return [];
    }
};

export default getGroupMembers;