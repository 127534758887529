// React
import React, { useState, useEffect } from "react";

// Context
import { UserAuth } from "context/AuthContext";

// chart.js - react-chartjs-2
import {
  Chart as ChartJS,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

// Components
import SystemConsumptionTable from "ui-components/SystemConsumptionTable";

// Firebase
import { db } from "config/firebase";
import {
  collection,
  query,
  where,
  getCountFromServer,
  getDocs,
} from "firebase/firestore";

// Functions
import getUserContacts from "user/getUserContacts";

// A ---------------------------------------------------------------------- M


ChartJS.register(
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const SystemConsumption = () => {
  const { isInternal } = UserAuth();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [records, setRecords] = useState([]);
  const [usersStats, setUsersStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        // Overview
        const recordPromises = Array.from({ length: 30 }).map(
          async (_, index) => {
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - index);
            currentDate.setHours(0, 0, 0, 0);
            const startOfDay = currentDate.getTime();
            const startOfDayUnixSeconds = startOfDay / 1000;
            currentDate.setHours(23, 59, 59, 999);
            const endOfDay = currentDate.getTime();
            const endOfDayUnixSeconds = endOfDay / 1000;

            const q = query(
              collection(db, "recordsdata"),
              where("timestamp", ">=", startOfDayUnixSeconds.toString()),
              where("timestamp", "<=", endOfDayUnixSeconds.toString())
            );

            const snapshot = await getCountFromServer(q);

            const formattedDate = currentDate.toISOString().split("T")[0];

            return {
              x: formattedDate,
              y: snapshot.data().count,
            };
          }
        );

        const records = await Promise.all(recordPromises);
        const recordsFiltered = records.filter((obj) => obj !== undefined);
        setRecords(recordsFiltered.reverse());

        // Users statistics
        const usersdataCollectionRef = collection(db, "userdata");
        const usersSnapshot = await getDocs(usersdataCollectionRef);

        const usersStatsPromises = usersSnapshot.docs.map(async (userDoc) => {
          const userID = userDoc.id;
          const mysigsCollectionRef = collection(
            usersdataCollectionRef,
            `${userID}/mysigs`
          );

          const last5Days = new Date();
          last5Days.setDate(last5Days.getDate() - 5);
          const last5DaysTimestampSecondsString = (
            last5Days.getTime() / 1000
          ).toString();

          const last30Days = new Date();
          last30Days.setDate(last30Days.getDate() - 30);
          const last30DaysTimestampSecondsString = (
            last30Days.getTime() / 1000
          ).toString();

          const last5DaysQuery = query(
            mysigsCollectionRef,
            where("timestamp", ">=", last5DaysTimestampSecondsString)
          );

          const last5DaysSnapshot = await getCountFromServer(last5DaysQuery);

          const last30DaysQuery = query(
            mysigsCollectionRef,
            where("timestamp", ">=", last30DaysTimestampSecondsString)
          );

          const last30DaysSnapshot = await getCountFromServer(last30DaysQuery);

          const userEmail = await getUserContacts(userID);

          return {
            userID: userID,
            userEmail: userEmail.email,
            last5DaysCount: last5DaysSnapshot.data().count,
            last30DaysCount: last30DaysSnapshot.data().count,
          };
        });

        const usersStats = await Promise.all(usersStatsPromises);
        const usersStatsFiltered = usersStats.filter(
          (obj) => obj !== undefined
        );
        setUsersStats(usersStatsFiltered);
      } catch (e) {
        console.error("Error in fetching data:", e.message);
        setUsersStats([]);
      }
      setIsLoading(false);
    };

    fetch();
  }, []);

  const data = {
    labels: records.map((record) => record.x),
    datasets: [
      {
        label: "Total N. Certifications",
        data: records.map((record) => record.y),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        suggestedMin: 0,
      },
    },
  };

  return (
    <>
      {isInternal ? (
        isLoading ? (
          <Grid item container justifyContent="center" mt="30%">
            <CircularProgress />
          </Grid>
        ) : (
          <Grid item container spacing={4}>
            <Grid item xs={12} md={12}>
              <Typography variant={isMobile ? "h6" : "h5"} gutterBottom>
                <b>System Level Panel</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ p: "1%" }}>
                <Typography variant="h6" gutterBottom>
                  <b>Total N. Certifications</b>
                </Typography>
                <Line options={options} data={data} height="85vh" />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <SystemConsumptionTable usersStats={usersStats} />
            </Grid>
          </Grid>
        )
      ) : (
        <Typography align="center" variant="h6" color="gray">
          In order to activate the System Level Panel, please contact @Armilis
        </Typography>
      )}
    </>
  );
};

export default SystemConsumption;
