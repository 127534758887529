import { doc, collection, getDocs } from 'firebase/firestore';
import { db } from 'config/firebase';

import fetchSignature from '../record/fetchSignature';
import getTagInfo from 'tag/getTagInfo';

// A ---------------------------------------------------------------------- M

const fetchSignatures = async (uuid) => {
    try {
        const userRef = doc(db, 'userdata', uuid);
        const mysigsRef = collection(userRef, 'mysigs');
        const snapshot = await getDocs(mysigsRef);
        const signaturePromises = snapshot.docs.map(async (sig) => {
            const signature = await fetchSignature(sig.id);

            if (!signature.tdr) {
                return {
                    txid: signature.id,
                    timestamp: sig.data().timestamp,
                    type: signature.type,
                    data: signature.data,
                    creator_uuid: uuid
                }
            }

            const tagInfo = await getTagInfo(signature.tdr);
            const name = tagInfo ? (tagInfo.name || 'N/A') : 'N/A';

            return {
                txid: signature.id,
                timestamp: sig.data().timestamp,
                type: signature.type,
                data: signature.data,
                name: name,
                tdr: sig.data().tdr,
                creator_uuid: uuid
            }
        });

        const signatures = await Promise.all(signaturePromises);
        const signaturesFiltered = signatures.filter(obj => obj !== undefined);
        return signaturesFiltered;
    } catch (e) {
        console.error('Error in fetchSignatures:', e.message);
        return [];
    }
};

export default fetchSignatures;