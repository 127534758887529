// React
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

// Components
import GroupToolbar from "ui-components/GroupToolbar";
import MembersTable from "ui-components/MembersTable";
import AddMember from "ui-components/AddMember";
import {
  MemberAddedSuccessfulSnackbar,
  MemberAddedErrorSnackbar,
  MemberRemovedSuccessfulSnackbar,
  MemberRemovedErrorSnackbar,
} from "ui-components/ORFeedbacks";

// Firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// Functions
import getGroup from "group/getGroup";
import getGroupMembers from "group/getGroupMembers";

// A ---------------------------------------------------------------------- M

const GroupMembers = () => {
  const groupID = useParams().group;
  const { t } = useTranslation();

  const [group, setGroup] = useState();
  const [members, setMembers] = useState([]);
  const [open, setOpen] = useState(false);
  const [openMemberAddedSuccessful, setOpenMemberAddedSuccessful] =
    useState(false);
  const [openMemberAddedError, setOpenMemberAddedError] = useState(false);
  const [openMemberRemovedSuccessful, setOpenMemberRemovedSuccessful] =
    useState(false);
  const [openMemberRemovedError, setOpenMemberRemovedError] = useState(false);

  useEffect(() => {
    const groupsdataDocRef = doc(db, "groupsdata", groupID);
    const usersCollectionRef = collection(groupsdataDocRef, "users");

    const fetchGroupMembers = async () => {
      const group = await getGroup(groupID);
      const members = await getGroupMembers(groupID);
      setGroup(group);
      setMembers(members);
    };

    fetchGroupMembers();

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);

    const newAdditionsQuery = query(
      usersCollectionRef,
      where("added_on", ">", currentTimestampInSeconds.toString())
    );

    const newAdditionsUnsubscribe = onSnapshot(
      newAdditionsQuery,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            fetchGroupMembers();
          }
        });
      }
    );

    const deletionUnsubscribe = onSnapshot(usersCollectionRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          fetchGroupMembers();
        }
      });
    });

    return () => {
      newAdditionsUnsubscribe();
      deletionUnsubscribe();
    };
  }, [groupID]);

  const handleOpenMemberAddedSuccessful = () => {
    setOpenMemberAddedSuccessful(true);
    window.history.pushState(null, "");
  };

  const handleOpenMemberAddedError = () => {
    setOpenMemberAddedError(true);
    window.history.pushState(null, "");
  };

  const handleOpenMemberRemovedSuccessful = () => {
    setOpenMemberRemovedSuccessful(true);
    window.history.pushState(null, "");
  };

  const handleOpenMemberRemovedError = () => {
    setOpenMemberRemovedError(true);
    window.history.pushState(null, "");
  };

  return (
    <>
      <Grid item container xs={12} spacing={5}>
        {group && (
          <>
            <Grid item xs={12}>
              <GroupToolbar group={group} />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ borderBottomWidth: 1, bgcolor: "black" }} />
            </Grid>

            <Grid item container xs={12} spacing={5}>
              <Grid item xs={12}>
                <Typography variant="h5" fontWeight="bold">
                  {t("members")}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <MembersTable
                  group={group}
                  members={members}
                  setOpen={setOpen}
                  handleSuccessfulRemoved={handleOpenMemberRemovedSuccessful}
                  handleErrorRemoved={handleOpenMemberRemovedError}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      {group && (
        <>
          <AddMember
            group={group}
            open={open}
            setOpen={setOpen}
            handleSuccessfulAdded={handleOpenMemberAddedSuccessful}
            handleErrorAdded={handleOpenMemberAddedError}
          />
          <MemberAddedSuccessfulSnackbar
            open={openMemberAddedSuccessful}
            setOpen={setOpenMemberAddedSuccessful}
          />
          <MemberAddedErrorSnackbar
            open={openMemberAddedError}
            setOpen={setOpenMemberAddedError}
          />
          <MemberRemovedSuccessfulSnackbar
            open={openMemberRemovedSuccessful}
            setOpen={setOpenMemberRemovedSuccessful}
          />
          <MemberRemovedErrorSnackbar
            open={openMemberRemovedError}
            setOpen={setOpenMemberRemovedError}
          />
        </>
      )}
    </>
  );
};

export default GroupMembers;
