// React
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from 'context/AuthContext';

// Material UI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

// Components
import { ResetPasswordDialog } from 'ui-components/ORFeedbacks';

// A ---------------------------------------------------------------------- M

export default function ResetPassword() {
    const { resetPassword } = UserAuth();

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [openReset, setOpenReset] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await resetPassword(email);
        setOpenReset(true);
    };

    const handleCloseReset = () => {
        setOpenReset(false);
        navigate('/signin');
    };

    return (
        <>
            <Container component="main" maxWidth='sm'>
                <CssBaseline />
                <Box
                    sx={{
                        mt: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h4" sx={{ mb: 4 }}>
                        <b>Reset Password</b>
                    </Typography>
                    <Typography variant="h6" sx={{ mb: 4 }}>
                        <b>Enter your email address to reset your password</b>
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    id="Email Address"
                                    label="Email Address"
                                    name="Email Address"
                                    required
                                    fullWidth
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={!email}
                                >
                                    Reset Password
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>

            <ResetPasswordDialog open={openReset} handleClose={handleCloseReset} />
        </>
    );
};