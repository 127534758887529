import sendCertificationStreamDoc from './sendCertificationStreamDoc';
import sendDataStreamDoc from './sendDataStreamDoc';
import unixTimestampInSeconds from 'utils/unixTimestampInSeconds';

// A ---------------------------------------------------------------------- M

const genDocRecord = async (isPublic, uid, keypair, fileByteArray, downloadURL, comment, tdr) => {
	try {
		const timestamp = unixTimestampInSeconds();

		if (tdr) {
			// Certification stream
			const data = await sendCertificationStreamDoc(keypair, fileByteArray, comment, timestamp, tdr);

			if (data.txid) {
				// Data stream
				const outcome = await sendDataStreamDoc(isPublic, uid, downloadURL, comment, data, timestamp, tdr);

				if (outcome.success) {
					console.log(data);
					return {
						inserted: data.inserted,
						txid: data.txid
					};
				} else {
					return {
						error: outcome.error
					};
				}
			} else {
				return {
					error: data.error
				};
			}
		} else {
			// Certification stream
			const data = await sendCertificationStreamDoc(keypair, fileByteArray, comment, timestamp);

			if (data.txid) {
				// Data stream
				const outcome = await sendDataStreamDoc(isPublic, uid, downloadURL, comment, data, timestamp);

				if (outcome.success) {
					console.log(data);
					return {
						inserted: data.inserted,
						txid: data.txid
					};
				} else {
					return {
						error: outcome.error
					};
				}
			} else {
				return {
					error: data.error
				};
			}
		}
	} catch (e) {
		console.error('Error in genDocRecord:', e.message);

		return {
			error: e.message
		};
	}
};

export default genDocRecord;