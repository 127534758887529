// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from 'firebase/firestore';

// A ---------------------------------------------------------------------- M

/**
 * Checks if a user is read-only authorized for a specific databox.
 * 
 * @param {string} tagID - The ID of the databox to check read-only permissions for.
 * @param {string} userID - The ID of the user to check authorization.
 * @returns {Promise<boolean>} - A promise that resolves to true if the user is read-only authorized for the databox, false otherwise.
 * 
 * 
 */
const isUserAuthorizedForDataBoxReadOnly = async (tagID, userID) => {
    try {
        const userRef = doc(db, `tagpermissions_readonly/${tagID}/authorized_users/${userID}`);
        const userSnap = await getDoc(userRef);
        return userSnap.exists();
    } catch (e) {
        console.error("Error in checking user read-only authorization for databox:", e.message);
        return false;
    }
};

export default isUserAuthorizedForDataBoxReadOnly;