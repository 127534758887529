// React
import React, { useState, useEffect } from "react";

// Context
import { UserAuth } from "context/AuthContext";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";

// Components
import { Puller } from "ui-components/Puller";
import { LoadingComponent } from "ui-components/LoadingComponent";

// Icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// Functions
import getDataKeysByOwner from "datakey/getDataKeysByOwner";
import addAuthorizedKey from "tag/addAuthorizedKey";

// A ---------------------------------------------------------------------- M

const AddDataKey = ({ tag, dataKeys, open, setOpen }) => {
  const { user } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [keys, setKeys] = useState([]);
  const [addedKeys, setAddedKeys] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => window.removeEventListener("popstate", handlePopstate);
  }, [open]);

  useEffect(() => {
    const fetch = async () => {
      const fetchedKeys = await getDataKeysByOwner(user.uid);
      setKeys(fetchedKeys);
      setLoading(false);
    };

    if (open) fetch();
  }, [dataKeys, user.uid, open]);

  const handleAddKey = async (keyId) => {
    await addAuthorizedKey(tag.id, keyId);
    setAddedKeys((current) => [...current, keyId]);
  };

  const handleReset = () => {
    setAddedKeys([]);
    setOpen(false);
  };

  const isKeyAdded = (keyId) =>
    addedKeys.includes(keyId) || dataKeys.some((key) => key.id === keyId);

  const TableRowWithToggle = ({ name, datakey, added, handleAdd }) => {
    const [showDataKey, setShowDataKey] = useState(false);

    const toggleDataKeyVisibility = () => {
      setShowDataKey(!showDataKey);
    };

    return (
      <TableRow>
        <TableCell>{name}</TableCell>
        <TableCell
          align="left"
          sx={{
            minWidth: !showDataKey ? "60px" : "120px",
            maxWidth: !showDataKey ? "60px" : "120px",
          }}
        >
          {showDataKey ? datakey : "••••••"}
          <IconButton onClick={toggleDataKeyVisibility} size="small">
            {showDataKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right">
          <Button onClick={handleAdd} disabled={added}>
            {added ? t("added") : t("add")}
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  const renderTable = () => (
    <Box sx={{ p: isMobile ? "5%" : "2%" }}>
      <Grid
        container
        alignItems="center"
        justifyContent={isMobile ? "center" : "space-between"}
        mt={isMobile ? "5%" : "0%"}
      >
        <Grid item>
          <Typography variant="h5" fontWeight="bold">
            {t("add_datakey")}
          </Typography>
        </Grid>
        {!isMobile && (
          <Grid item>
            <IconButton onClick={handleReset} sx={{ color: "red" }} edge="end">
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">{t("name")}</TableCell>
              <TableCell align="left">{t("datakey")}</TableCell>
              <TableCell align="right">{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keys.map((key) => {
              const added = isKeyAdded(key.id);
              return (
                <TableRowWithToggle
                  key={key.id}
                  name={key.name}
                  datakey={key.datakey}
                  added={added}
                  handleAdd={() => !added && handleAddKey(key.id)}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{ "& .MuiDrawer-paper": { width: "100%", height: "90%" } }}
      PaperProps={{
        sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
      }}
    >
      <Puller />
      {loading ? <LoadingComponent /> : renderTable()}
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={handleReset} fullWidth maxWidth="md">
      {loading ? <LoadingComponent /> : renderTable()}
    </Dialog>
  );
};

export default AddDataKey;
