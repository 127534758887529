// Firebase
import { db } from 'config/firebase';
import { collectionGroup, query, where, getDocs, getDoc } from 'firebase/firestore';

// Functions
import getTagInfo from './getTagInfo';

// A ---------------------------------------------------------------------- M

const getTagsByViewGroup = async (groupID) => {
    try {
        const groupsQuery = query(
            collectionGroup(db, 'viewgroups'),
            where('group_id', '==', groupID)
        );

        const snapshot = await getDocs(groupsQuery);
        const tagPromises = snapshot.docs.map(async (group) => {
            const tag = await getDoc(group.ref.parent.parent);
            const tagID = tag.id;
            const tagInfo = await getTagInfo(tagID);

            return {
                id: tagID,
                name: tagInfo.name || 'N/A',
            }
        });

        const tags = await Promise.all(tagPromises);
        return tags;
    } catch (e) {
        console.error("Error in getTagsByViewGroup:", e.message);
        return [];
    }
};

export default getTagsByViewGroup;