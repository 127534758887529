// Firebase
import { db } from "config/firebase";
import { doc, collection, deleteDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const removeViewGroup = async (tagID, groupID) => {
    try {
        const tagRef = doc(db, 'tagsdata', tagID);
        const viewgroupsRef = collection(tagRef, 'viewgroups');
        const viewgroupRef = doc(viewgroupsRef, groupID);
        await deleteDoc(viewgroupRef);
        return { code: 0 };
    } catch (e) {
        console.error("Error in removeViewGroup:", e.message);
        return { code: 1 };
    }
};

export default removeViewGroup;