// Firebase
import { db } from 'config/firebase';
import { doc, getDoc } from 'firebase/firestore';

// A ---------------------------------------------------------------------- M

const getTagTypes = async (tagID) => {
    try {
        const tagRef = doc(db, 'tagsdata', tagID);
        const tagSnapshot = await getDoc(tagRef);

        if (tagSnapshot.exists()) {
            const tagData = tagSnapshot.data();

            if (tagData.types) {
                return tagData.types;
            } else {
                return [];
            }
        }
    } catch (e) {
        console.error("Error in getTagTypes:", e.message);
        return [];
    }
};

export default getTagTypes;