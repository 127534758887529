// Firebase
import { db } from "config/firebase";
import { collection, addDoc, doc } from "firebase/firestore";

// Functions
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

/**
 * Adds a new forward route to the Firestore database.
 * Creates a new document in the 'forward_routes' subcollection of the specified databox document.
 *
 * @param {string} tagID - The unique identifier of the databox document in Firestore.
 * @param {Object} body - The data to be added to the new document.
 * @param {string} body.endpoint - The endpoint URL of the forward route.
 * @param {string} body.type - The type URL of the forward route (e.g. REST).
 * @param {Boolean} body.status - The status of the forward route (true/false).
 * @param {Array<string>} body.selectedTypes - An array of selected types for the forward route.
 * @param {Object} body.headers - The headers to be added to the subsequent endpoint request.
 */
const addForwardRoute = async (tagID, body) => {
  try {
    const { endpoint, type, status, selectedTypes, headers } = body;
    const tagRef = doc(db, "tagsdata", tagID);
    const forwardRoutesRef = collection(tagRef, "forward_routes");
    const timestamp = unixTimestampInSeconds();

    const docRef = await addDoc(forwardRoutesRef, {
      endpoint: endpoint,
      type: type,
      enabled: status,
      types: selectedTypes,
      headers: headers,
      added_on: timestamp,
      last_modified: timestamp,
    });

    console.log(
      `Document with ID ${docRef.id} has been successfully added to tag ID ${tagID}.`
    );
  } catch (e) {
    console.error("Error in addForwardRoute:", e.message);
  }
};

export default addForwardRoute;
